import React from "react";

export type UserType = {
    id: string,
    email: string,
    username: string,
    isKitron: boolean,
    kitronDate: string,
    admin: boolean,
    currentRole: "Labelling" | "Segmentation",
    labellingInfo: {
        level: 0 | 1 | 2 | 3,
        level1Date: string,
        level2Date: string,
        level3Date: string,
        has_quizzes?,
        can_take_exam?
    },
    segmentationInfo: {
        level: 1 | 2 | 3,
        level1Date: string,
        level2Date: string,
        level3Date: string
    },
    signUpDate: string,
    company: "KITRO" | "Adept" | "HiTech",
    dailyAvg: number
};

export const UserContext = React.createContext<{
    user: UserType,
    setUser:() => void
      }>({
        user: null,
        setUser: () => {} // eslint-disable-line
      });

// export default UserContext;
