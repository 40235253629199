import React, { useContext } from "react";

import { signOut } from "aws-amplify/auth";

import { DropdownItem, DropdownMenu, DropdownToggle, Nav } from "reactstrap";

import { AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from "@coreui/react";
import BuildIcon from "@material-ui/icons/Build";
import SchoolIcon from "@material-ui/icons/School";
import FeedbackIcon from "@material-ui/icons/Feedback";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SearchIcon from "@material-ui/icons/Search";
import BarChartIcon from "@material-ui/icons/BarChart";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import logo from "../../assets/img/brand/logo.png";
import { withRouter, useHistory } from "react-router-dom";

import { UserContext } from "../../context/userContext";

const DefaultHeader = () => {
  const logout = () => {
    signOut();
    window.location.reload();
  };

  const { user } = useContext(UserContext);
  const history = useHistory();

  return <React.Fragment>
    <AppNavbarBrand
      full={{
        src: logo, width: 100, height: 30, alt: "KITRO Logo"
      }}
      minimized={{
        src: logo, width: 30, height: 30, alt: "KITRO Logo min"
      }}
    />
    <Nav className="ml-auto" navbar>
      <AppHeaderDropdown direction="down">
        <DropdownToggle nav>
          <AppSidebarToggler display="lg" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem header tag="div" className="text-center"><strong>Settings</strong></DropdownItem>
          { user.isKitron && <DropdownItem onClick={() => history.push("/controlPanel")}><BuildIcon/> Control Panel</DropdownItem> }
          { user.isKitron && <DropdownItem onClick={() => history.push("/labelChecker")}><SearchIcon/> Label Checker</DropdownItem> }
          { user.isKitron && <DropdownItem onClick={() => history.push("/dataValidity")}><ImageOutlinedIcon/> Data Validity</DropdownItem> }
          <DropdownItem onClick={() => history.push("/performanceReport")}><BarChartIcon/> Performance Report</DropdownItem>
          <DropdownItem onClick={() => history.push("/training")}><SchoolIcon/> Training</DropdownItem>
          <DropdownItem onClick={() => history.push("/feedback")}><FeedbackIcon/> Agent Feedback</DropdownItem>
          <DropdownItem onClick={() => history.push("/user")}><AccountCircleIcon/> Profile</DropdownItem>
          <DropdownItem onClick={logout}><ExitToAppIcon/> Logout</DropdownItem>
        </DropdownMenu>
      </AppHeaderDropdown>
    </Nav>
  </React.Fragment>;
};

export default withRouter(DefaultHeader);
