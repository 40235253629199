import React, { useState, useContext } from "react";
import { UserContext } from "../../context/userContext";
import useFetch from "../../utils/useFetch";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import MailIcon from "@material-ui/icons/Mail";
import EditIcon from "@material-ui/icons/Edit";
import AssignmentIcon from "@material-ui/icons/Assignment";
import StarOutlinedIcon from "@material-ui/icons/StarOutlined";
import EventIcon from "@material-ui/icons/Event";
import Modal from "react-modal";
import { Switch } from "@material-ui/core";
import { getFetch } from "../../utils/fetchUtils";


type LevelInfo = {
  level: number,
  level1Date: string,
  level2Date: string,
  level3Date: string
}

type Agent = {
  id: number,
  username: string,
  company_identifier: string,
  email: string,
  is_kitron: boolean,
  sign_up_date: string,
  user_level: number,
  data: {
    active: boolean,
    admin: boolean,
    assigned_deployments?: any,
    currentRole: "Labelling" | "Segmentation",
    kitronDate: string,
    labellingInfo: LevelInfo,
    segmentationInfo: LevelInfo,
    max_tasks: number
  }
}

const customModalStyles = {
  content: {
    width: "fit-content",
    height: "fit-content",
    left: "50%",
    top: "53%",
    transform: "translate(-50%, -50%)",
  }
};


const UpdateAgentModal: React.FC<{
  isOpen: boolean,
  onRequestClose: () => void,
  agent: Agent,
  setAgentData: (newValues: any) => void
}> = ({isOpen, onRequestClose, agent, setAgentData}) => {
  const [isActive, setIsActive] = useState<boolean>("active" in agent.data ? agent.data.active : true);
  const [maxTasks, setMaxTasks] = useState<number>(agent.data.max_tasks);
  const [wrongInput, setWrongInput] = useState<boolean>(false);

  const handleSubmit = (): void => {
    if (!Number.isInteger(maxTasks) || maxTasks < 0) {
      setWrongInput(true);
      setTimeout(() => {
        setWrongInput(false);
      }, 4000);
      return;
    }
    getFetch("/set-agent", {
      data: {
        "agent_id": agent.id,
        "enabled": isActive,
        "max_tasks": maxTasks
      },
      method: "POST"
    })
      .then(() => {
        setAgentData({active: isActive, max_tasks: maxTasks});
        onRequestClose();
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="AgentModal"
      style={customModalStyles}
      ariaHideApp={false}
    >
      <h1>Updating <b>{agent.username} (#{agent.id})</b></h1>
      <span>
          Enable user
      </span>
      <Switch
        color="primary"
        checked={isActive}
        onChange={(event) => setIsActive(event.target.checked)}
      />

      <div className="mt-4">
          Daily task limit
        <input
          type="number"
          className="form-control"
          aria-label="Default"
          aria-describedby="inputGroup-sizing-default"
          value={maxTasks}
          onChange={(event) => setMaxTasks(parseInt(event.target.value))}
        />
        <p style={{color: "red", visibility: wrongInput ? "visible" : "hidden"}} className='mb-0'>Add a valid number.</p>
      </div>
      <button type="button" className="btn btn-primary mt-2" onClick={handleSubmit}>Apply</button>

    </Modal>
  );
};


const AgentInfoCard: React.FC<{
  agentData: Agent,
  canEdit: boolean,
  openModal: () => void
}> = ({agentData, canEdit, openModal}) => <div className='report-card'>
  <div
    className={agentData.data.active ? "card-header" : "card-header-disabled"}
    style={{cursor: canEdit ? "pointer" : "default"}}
    {...(canEdit && {onClick: openModal}) }
  >
    {agentData.username} (#{agentData.id})
    {canEdit && <EditIcon fontSize="small" style={{margin: "0 0 5px 5px"}}/>}
  </div>
  <div className='card-content'>
    <p title="Company"><BusinessCenterIcon /><span>{agentData.company_identifier}</span></p>
    <p title="E-mail Address"><MailIcon /><span>{agentData.email}</span></p>
    <p title="Agent Levels"><StarOutlinedIcon /><span>Labelling {agentData.data.labellingInfo.level} / Segmentation {agentData.data.segmentationInfo.level}</span></p>
    <p title="Signup Date"><EventIcon /><span>{agentData.sign_up_date}</span></p>
    <p title="Daily task limit"><AssignmentIcon /><span>{agentData.data.max_tasks}</span></p>
  </div>
</div>;

const AgentInfoCards: React.FC<{
  agentIDs: string[]
}> = ({agentIDs}) => {
  const agentData = useFetch<Agent[]>(`/agent-performance/get-agent-info?agent=${agentIDs}`);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedAgentID, setSelectedAgentID] = useState<number | null>();
  const { user } = useContext(UserContext);

  const openAgentModal = (agentInd: number): void => {
    setSelectedAgentID(agentInd);
    setModalOpen(true);
  };

  return (
    <>
      { modalOpen && <UpdateAgentModal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(!modalOpen)}
        agent={agentData.data[selectedAgentID]}
        setAgentData={(newValues) => { agentData.data[selectedAgentID].data = {...agentData.data[selectedAgentID].data, ...newValues}; }}
      />
      }

      { agentData.error &&
        <div className='report-cards-container'>Error: {agentData.error}</div>}

      { !agentData.loaded &&
        <div className='report-cards-container'>Loading...</div> }

      { agentData.loaded && !agentData.error &&
          <div className='report-cards-container'>{agentData.data.map((agent, ind) => <AgentInfoCard
            key={agent.id}
            agentData={agent}
            canEdit={user.admin && user.company === agent.company_identifier}
            openModal={() => openAgentModal(ind)}
          />
          )}
          </div>
      }
    </>
  );
};

export { AgentInfoCards };
