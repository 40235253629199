/* eslint-disable no-multi-assign */
import { useState, useEffect, useCallback, useRef } from "react";
import { mousePosType, sizesType, toolPropsType } from "./types";

const UNDO_REDO_ON = true;

class CanvasHistory {
  redos: string[];
  undos: string[];
  max_length: number;
  changes: number;

  constructor() {
    this.reset();
  }

  reset() {
    this.redos = [];
    this.undos = [];
    // set `max_length` to -1 for infinite undo/redo;
    // set to 0 to basically disable undo/redo
    this.max_length = 5;
    // number of changes done on the canvas, accounting
    // for undo/redo; this in turn is used to determine
    // whether the mask was edited by the agent
    this.changes = 0;
  }

  saveState(canvas: HTMLCanvasElement, list?: string[], keep_redo?: boolean) {
    keep_redo = keep_redo || false;
    if (!keep_redo)
      this.redos = [];
    list = list || this.undos;
    list.push(canvas.toDataURL());
    if ((this.max_length >= 0) && (list.length > this.max_length))
      list.shift();
  }

  async undo(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) {
    await this.restoreState(canvas, ctx, this.undos, this.redos);
  }
  async redo(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) {
    await this.restoreState(canvas, ctx, this.redos, this.undos);
  }

  // restore content of canvas, moving one copy of canvas from `pop` to `push`;
  // if there's nothing to "pop", resolves to false
  // returns a `Promise` so that the click handler does not complain about timeout,
  // while waiting for the image element to load the content (`img.onload()`)
  restoreState(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, pop: string[], push: string[]) {
    return new Promise((resolve, reject) => {
      if (pop.length) {
        this.saveState(canvas, push, true);
        const restore_state = pop.pop();
        // var img = new Element('img', { 'src': restore_state });
        const img = document.createElement("img");
        img.onload = () => {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 0, 0);
          resolve(true);
        };
        img.src = restore_state;
      }
      else
        resolve(false);
    });
  }
}
const canvasHistory = new CanvasHistory();

type drawParams = {
  viewCtx: CanvasRenderingContext2D,
  img: HTMLImageElement,
  canvas: HTMLCanvasElement,
  showMask: boolean,
  brushPreview: boolean,
  mousePos: mousePosType,
  viewport: sizesType,
  sizes: sizesType,
  toolProps: toolPropsType
}

const draw = ({ viewCtx, img, canvas, showMask, brushPreview, mousePos, viewport, sizes, toolProps }: drawParams) => {
  if (viewCtx && img && canvas) {
    // viewCtx.clearRect(0, 0, sizes[0], sizes[1]);   // no need to clear, as we paste image all over it
    viewCtx.globalAlpha = 1;
    viewCtx.drawImage(img, viewport[0], viewport[1], viewport[2], viewport[3], 0, 0, sizes[0], sizes[1]);
    if (showMask) {
      viewCtx.globalAlpha = 0.5;
      viewCtx.drawImage(canvas, viewport[0], viewport[1], viewport[2], viewport[3], 0, 0, sizes[0], sizes[1]);
    }
    if (brushPreview && mousePos[2]) {
      viewCtx.globalAlpha = 1;
      viewCtx.globalCompositeOperation = "difference";
      viewCtx.strokeStyle = "white";
      viewCtx.lineWidth = 1;

      viewCtx.beginPath();
      viewCtx.arc(mousePos[0], mousePos[1], toolProps.width / 2, 0, 2 * Math.PI);
      viewCtx.stroke();
      viewCtx.closePath();

      viewCtx.globalCompositeOperation = "source-over";
    }
  }
};

export type usePaintCanvasHandle = {
  doUndo: () => Promise<void>,
  doRedo: () => Promise<void>,
  canUndo: boolean,
  canRedo: boolean,
  wasChanged: boolean,
  onClick: (event: React.SyntheticEvent<HTMLCanvasElement, any>) => boolean,
  onMove: (event: React.SyntheticEvent<HTMLCanvasElement, MouseEvent>) => void,
  eraseMask: () => void,
  getMaskAsPNG: () => string,
  drawView: () => void
};

type usePaintCanvasParams = {
  canvas: HTMLCanvasElement,
  view: HTMLCanvasElement,
  toolProps: toolPropsType,
  showMask: boolean,
  sizes: sizesType,
  zoom: number,
  zoomIn: () => void,
  zoomOut: () => void,
  brushPreview: boolean,
  incBrush: () => void,
  decBrush: () => void,
  img: HTMLImageElement
}

const usePaintCanvas = ({canvas, view, toolProps, showMask, sizes, zoom, zoomIn, zoomOut, brushPreview, incBrush, decBrush, img }: usePaintCanvasParams): usePaintCanvasHandle => {
  const canvasCtx = canvas ? canvas.getContext("2d") : null;
  const viewCtx = view ? view.getContext("2d") : null;

  const viewport = useRef<sizesType>([0, 0, 100, 100]);
  const mousePos = useRef<mousePosType>([0, 0, false]); // x, y, is-in?

  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [wasChanged, setWasChanged] = useState(false);

  const pressedState = useRef({
    left: false, right: false, ctrl: false, shift: false
  });
  const prevPos = useRef([0, 0]);
  const didMove = useRef(false);

  const oldSizes = useRef<sizesType>([0, 0, 0, 0]);
  const oldZoom = useRef(1.0);

  const drawView = useCallback(() => draw({
    viewCtx, img, canvas, showMask, brushPreview, mousePos: mousePos.current, viewport: viewport.current, sizes, toolProps
  }), [brushPreview, canvas, img, showMask, sizes, toolProps, viewCtx]);

  const sanitizeViewport = useCallback((x: number, y: number, w: number, h: number) => {
    // make sure we can fit at all; ideally, this should preserve
    // aspect ratio, but this is not an ideal world...
    w = Math.min(w, sizes[2]);
    h = Math.min(h, sizes[3]);
    // make sure we're not too much left or up
    x = Math.max(0, x);
    y = Math.max(0, y);
    // make sure we're not too much right or down
    x = Math.min(x, sizes[2] - w);
    y = Math.min(y, sizes[3] - h);
    viewport.current = [x, y, w, h];
  }, [sizes]);

  useEffect(() => {
    const os = oldSizes.current;
    if ((canvas !== null) && (os[2] !== sizes[2]) && (os[3] !== sizes[3])) {
      canvas.width = os[2] = sizes[2];
      canvas.height = os[3] = sizes[3];
    }
    if ((view !== null) && (os[0] !== sizes[0]) && (os[1] !== sizes[1])) {
      view.width = os[0] = sizes[0];
      view.height = os[1] = sizes[1];
    }

    const oz = oldZoom.current;
    const w = sizes[0] / zoom;
    const h = sizes[1] / zoom;
    let x = viewport.current[0];
    let y = viewport.current[1];
    if (zoom !== oz) {
      if (oz !== null) {

        const mp = mousePos.current;
        if (mp[2]) {
          // mouse within image, zoom onto the mouse position
          x = x + mp[0] / oz - mp[0] / zoom;
          y = y + mp[1] / oz - mp[1] / zoom;
        }
        else {
          // mouse outside, zoom into the center of the area
          x -= (w - sizes[0] / oz) / 2;
          y -= (h - sizes[1] / oz) / 2;
        }
      }
      oldZoom.current = zoom;
    }
    sanitizeViewport(x, y, w, h);
    drawView();
    // DO NOT include "sanitizeViewport" and "viewport" among dependencies,
    // as it creates infinite update loop!
  }, [sizes, zoom, canvas, view, img, drawView, sanitizeViewport]);

  useEffect(() => {
    drawView();
  }, [showMask, brushPreview, toolProps, drawView]);

  const onClick = useCallback((event: React.SyntheticEvent<HTMLCanvasElement, any>) => {
    const nativeEvent = event.nativeEvent;
    const { offsetX, offsetY } = nativeEvent;

    if ((nativeEvent.type === "mouseup") || (nativeEvent.type === "mouseout")) {
      // nativeEvent is a MouseEvent
      if (!didMove.current && pressedState.current.ctrl) {
        if (pressedState.current.left)
          incBrush();
        else if (pressedState.current.right)
          decBrush();

        if (brushPreview)
          drawView();
      }
      // else if (!didMove.current && pressedState.current.shift) {
      //   if (pressedState.current.right)
      //     saveImage(canvas);
      // }
      pressedState.current = {
        left: false, right: false, ctrl: false, shift: false
      };
      didMove.current = false;
      if (nativeEvent.type === "mouseout" && brushPreview) {
        mousePos.current = [mousePos[0], mousePos[1], false];
        drawView();
      }
    }
    else if (nativeEvent.type === "mousedown") {
      // nativeEvent is a MouseEvent
      prevPos.current = [offsetX, offsetY];
      if (nativeEvent.button === 0)
        pressedState.current.left = true;
      else if (nativeEvent.button === 2)
        pressedState.current.right = true;
      if (nativeEvent.ctrlKey)
        pressedState.current.ctrl = true;
      if (nativeEvent.shiftKey)
        pressedState.current.shift = true;

      if (pressedState.current.left && !pressedState.current.ctrl) { // --> normal brush or eraser
        if (UNDO_REDO_ON) {
          canvasHistory.saveState(canvas);
          canvasHistory.changes++;
          setCanUndo(true);
          setCanRedo(false);
        }
        setWasChanged(true);

        const { width, color } = toolProps;
        canvasCtx.beginPath();
        canvasCtx.globalCompositeOperation = pressedState.current.shift ? "destination-out" : "source-over";
        canvasCtx.arc(offsetX / zoom + viewport.current[0], offsetY / zoom + viewport.current[1], width / zoom / 2, 0, 2 * Math.PI, false);
        canvasCtx.fillStyle = color;
        canvasCtx.fill();
        canvasCtx.globalCompositeOperation = "source-over";
        drawView();
      }
    }
    else if (nativeEvent.type === "wheel") {
      // nativeEvent is a WheelEvent
      if (!nativeEvent.shiftKey && !nativeEvent.ctrlKey) {
        nativeEvent.deltaY > 0 ? zoomIn() : zoomOut();
        drawView();
      }
      else if (nativeEvent.shiftKey && !nativeEvent.ctrlKey) {
        nativeEvent.deltaY > 0 ? incBrush() : decBrush();
        drawView();
      }
    }
    else if (nativeEvent.type === "contextmenu") {
      // this prevents the right click to make the context menu pop up...
      // we need to capture that event using onContextMenu prop,
      // even if we do nothing with it here...
      // the actual right click is 'mousedown' with `button` === 2
      event.preventDefault();
    }
    return false;
  }, [brushPreview, canvas, canvasCtx, decBrush, incBrush, zoomIn, zoomOut, drawView, toolProps, zoom]);

  const onMove = useCallback((event: React.SyntheticEvent<HTMLCanvasElement, MouseEvent>) => {
    const nativeEvent = event.nativeEvent;
    const { offsetX, offsetY } = nativeEvent;
    let shouldDraw = false;
    if (brushPreview) {
      mousePos.current = [offsetX, offsetY, true];
      shouldDraw = true;
    }

    if (!pressedState.current.left && !pressedState.current.right) {
      if (shouldDraw)
        drawView();
      return;
    }

    if (pressedState.current.left && !pressedState.current.ctrl) { // --> normal brush or eraser
      if (!didMove.current)
        didMove.current = true;

      const { width, color } = toolProps;
      canvasCtx.beginPath();
      canvasCtx.globalCompositeOperation = pressedState.current.shift ? "destination-out" : "source-over";
      canvasCtx.lineJoin = "round";
      canvasCtx.lineCap = "round";
      canvasCtx.strokeStyle = color;
      canvasCtx.lineWidth = width / zoom;
      canvasCtx.moveTo(prevPos.current[0] / zoom + viewport.current[0], prevPos.current[1] / zoom + viewport.current[1]);
      canvasCtx.lineTo(offsetX / zoom + viewport.current[0], offsetY / zoom + viewport.current[1]);
      canvasCtx.stroke();
      canvasCtx.globalCompositeOperation = "source-over";
      prevPos.current = [offsetX, offsetY];
      shouldDraw = true;
    }
    else if (pressedState.current.left && pressedState.current.ctrl && !pressedState.current.shift) { // -> move canvas
      if (!didMove.current)
        didMove.current = true;
      const dx = (offsetX - prevPos.current[0]) / zoom;
      const dy = (offsetY - prevPos.current[1]) / zoom;
      // if you want to appear to move the "window", do `+dx, +dy`.
      // here we choose to move the image behind the "window", thus `-dx, -dy`.
      sanitizeViewport(viewport.current[0] - dx, viewport.current[1] - dy, viewport.current[2], viewport.current[3]);
      prevPos.current = [offsetX, offsetY];
      shouldDraw = true;
    }
    if (shouldDraw)
      drawView();
  }, [brushPreview, canvasCtx, drawView, sanitizeViewport, toolProps, zoom]);

  // undo/redo handling
  const doUndo = async () => {
    if (canUndo) {
      await canvasHistory.undo(canvas, canvasCtx);
      setCanUndo(canvasHistory.undos.length > 0);
      setCanRedo(canvasHistory.redos.length > 0);
      canvasHistory.changes--;
      setWasChanged(canvasHistory.changes > 0);
      drawView();
    }
  };
  const doRedo = async () => {
    if (canRedo) {
      await canvasHistory.redo(canvas, canvasCtx);
      setCanUndo(canvasHistory.undos.length > 0);
      setCanRedo(canvasHistory.redos.length > 0);
      canvasHistory.changes++;
      setWasChanged(canvasHistory.changes > 0);
      drawView();
    }
  };

  const eraseMask = () => {
    canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
    if (UNDO_REDO_ON) {
      canvasHistory.reset();
      setCanUndo(false);
      setCanRedo(false);
      setWasChanged(false);
    }
  };

  const getMaskAsPNG = useCallback(() => canvas.toDataURL("image/png"), [canvas]);

  return {
    doUndo,
    doRedo,
    canUndo,
    canRedo,
    wasChanged,
    onClick,
    onMove,
    eraseMask,
    getMaskAsPNG,
    drawView
  };
};

export default usePaintCanvas;
