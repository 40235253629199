import React, { useEffect, useMemo, useRef, useState } from "react";
import $ from "jquery";

import { HotKeys } from "react-hotkeys";

import CanvasIntermediate, { CanvasIntermediateHandle } from "./CanvasIntermediate";
import SegmentationLabelsContainer from "./SegmentationLabelsContainer";
import LiveCountComponent, { LiveCountComponentHandle } from "../LiveCountComponent/LiveCountComponent";
import { getFetchResp } from "../../utils/fetchUtils";

import AWS from "aws-sdk";
import { RouteComponentProps } from "react-router-dom";
import { AllLabels } from "../../containers/LabelSelector/LabelSelector";
import { SegTrasheventInfo } from "./types";
const s3 = new AWS.S3({
  accessKeyId: "AKIA4ZN2FXCSXF3DZJSD", secretAccessKey: "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", useAccelerateEndpoint: false, signatureVersion: "v4", region: "eu-central-1"
});

type LocationState = {
  batchData?: {
    keys: number[],
    trashevents: {
      [key: string]: SegTrasheventInfo
    }
  },
  fullLabelData?: AllLabels,
  checkBatch?: boolean,
  batchID?: number
};

interface SegmentationCheckingProps extends RouteComponentProps<any, any, LocationState> {
}

const SegmentationChecking = (props: SegmentationCheckingProps) => {
  useEffect(() => {
    if (!props.history.location.state)
      props.history.push("/");

    if (props.history.location.state.batchData.keys.length === 0)
      props.history.push("/");

  }, [props.history]);

  const canvasIntermediateRef = useRef<CanvasIntermediateHandle>();
  const liveCountRef = useRef<LiveCountComponentHandle>();

  const getURL = (image_key: string) => {
    if (image_key === null)
      return null;
    return s3.getSignedUrl("getObject", { Bucket: "kitroengine-s3", Key: image_key });
  };

  const imageData = props.history.location.state.batchData.keys;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentLabelIndex, setCurrentLabelIndex] = useState(1);
  const [viewMode, setViewMode] = useState("L");
  const resetUI = 0;
  const [batchData, setBatchData] = useState(props.history.location.state.batchData.trashevents);
  const batchSize = imageData.length;
  const checkBatch = props.history.location.state.checkBatch;
  const [prevURL, setPrevURL] = useState(batchData[imageData[currentIndex]] ? getURL(batchData[imageData[currentIndex]].prev_image_id) : null);
  const [currURL, setCurrURL] = useState(batchData[imageData[currentIndex]] ? getURL(batchData[imageData[currentIndex]].image_id) : null);
  const [maskURL, setMaskURL] = useState(batchData[imageData[currentIndex]] ? getURL(batchData[imageData[currentIndex]].mask_id || null) : null);
  const [imageShown, setImageShown] = useState(0);
  const [activeColor_, setActiveColor_] = useState(0);
  const [doSave, setDoSave] = useState(false);
  const [maskChanged, setMaskChanged] = useState(false);
  const labelColor = useMemo(() => ({
    0: "rgba(0, 255, 255, 0.4)",
    1: "rgba(255, 0, 0, 0.4)",
    2: "rgba(0, 255, 0, 0.4)",
    3: "rgba(0, 0, 255, 0.4)",
    4: "rgba(255, 255, 0, 0.4)",
    5: "rgba(255, 0, 255, 0.4)",
    6: "rgba(0, 0, 0, 0.4)",
    7: "rgba(255, 255, 255, 0.4)",
    99: "rgba(128, 128, 128, 0.4)"
  }), []);
  const labelColorSolid = useMemo(() => ({
    0: "rgba(0, 255, 255, 0.8)",
    1: "rgba(255, 0, 0, 0.8)",
    2: "rgba(0, 255, 0, 0.8)",
    3: "rgba(0, 0, 255, 0.8)",
    4: "rgba(255, 255, 0, 0.8)",
    5: "rgba(255, 0, 255, 0.8)",
    6: "rgba(0, 0, 0, 0.8)",
    7: "rgba(255, 255, 255, 0.8)",
    99: "rgba(128, 128, 128, 0.8)",
  }), []);

  const saveImageCall = () => setDoSave(true);

  const saveImage = (png: string) => {
    batchData[imageData[currentIndex]].maskData = png;
    setBatchData(batchData);
    setDoSave(false);
    approveAndSaveMask();
  };

  const approveAndSaveMask = () => {
    const currBatch = batchData[imageData[currentIndex]];
    currBatch.checking_status = "saved_approved";

    const postData = {"trashevent": currBatch};

    getFetchResp("/segment-image", {
      api: global.non_vpc_url, data: postData, method: "POST"
    })
      .then((response) => {
        if (response.status >= 400) {
          alert("Something went wrong labelling this image!");
          props.history.push({ pathname: "/", state: {} });
        }
        else
          setTimeout(() => { if (liveCountRef.current) liveCountRef.current.reload(); }, 1000);

      });

    currBatch.checking_status = "saved_improved";
    setBatchData(batchData);
    saveCheckingStatus(currBatch);
  };

  const approveMask = () => {
    batchData[imageData[currentIndex]].checking_status = "approved";
    setBatchData(batchData);
    saveCheckingStatus(batchData[imageData[currentIndex]]);
  };

  const ignoreMask = () => {
    batchData[imageData[currentIndex]].checking_status = "ignored";
    setBatchData(batchData);
    saveCheckingStatus(batchData[imageData[currentIndex]]);
  };

  const skipImage = () => {
    if (currentIndex + 1 === batchSize) {
      alert("Batch completed!");
      props.history.push("/");
    }
    else {
      canvasIntermediateRef.current.eraseMask();

      const nextBatch = batchData[imageData[currentIndex + 1]];
      setCurrentIndex(currentIndex + 1);
      setPrevURL(getURL(nextBatch.prev_image_id));
      setCurrURL(getURL(nextBatch.image_id));
      setMaskURL(getURL(nextBatch.mask_id));
    }
  };

  const saveCheckingStatus = (eventInfo: SegTrasheventInfo) => {
    $("#root").css("background-color", "#e4e5e6");

    $(".success-checkmark").css("display", "block");
    $(".check-icon").show();

    setTimeout(function () {
      $(".check-icon").hide();
      $(".success-checkmark").css("display", "none");
    }, 1000);

    canvasIntermediateRef.current.zoomReset();
    canvasIntermediateRef.current.drawView();
    canvasIntermediateRef.current.focus();

    let batchCompleted = false;

    if (currentIndex + 1 === batchSize)
      batchCompleted = true;
    else {
      canvasIntermediateRef.current.eraseMask();

      const nextBatch = batchData[imageData[currentIndex + 1]];
      setCurrentIndex(currentIndex + 1);
      setPrevURL(getURL(nextBatch.prev_image_id));
      setCurrURL(getURL(nextBatch.image_id));
      setMaskURL(getURL(nextBatch.mask_id));
    }

    getFetchResp("/check-segmented-image", { method: "POST", data: { "trashevent": eventInfo } })
      .then((response) => {
        if ((response.status >= 200) && (response.status < 300)) {
          if (liveCountRef.current)
            liveCountRef.current.reload();
        }
        else {
          alert("Something went wrong checking this image!");
          props.history.push({pathname: "/", state: {}});
        }
      });

    if (batchCompleted) {
      if (checkBatch) {
        alert("Batch completed!");
        props.history.push("/");
      }
      else
        props.history.push({ pathname: "/batchDone" });
    }
  };

  const nextImageEmpty = () => {
    if (currentIndex === currentLabelIndex) {
      setCurrentIndex(currentIndex + 1);
      setCurrentLabelIndex(currentLabelIndex + 1);
    }
    else {
      const vm = (currentIndex === currentLabelIndex - 1) ? "L" : "V";
      setViewMode(vm);
      setCurrentIndex(currentIndex + 1);
    }
  };

  const getDateString = () => {
    const myDate = new Date(batchData[imageData[currentIndex]].datetime);
    return myDate.toString().split("GMT")[0];
  };

  const labelComplete = () => {
    if (!(batchData[imageData[currentIndex]] && batchData[imageData[currentIndex]].label))
      return false;

    for (const label in batchData[imageData[currentIndex]].label.label_info) {
      const currentLabel = batchData[imageData[currentIndex]].label.label_info[label];
      if (currentLabel.percentage === 0 || currentLabel.id === 0 || currentLabel.value === 0 || currentLabel.label === "")
        return false;
    }

    return true;
  };

  const setErrorLabel = (type) => {
    // NOTE: i _think_ this is irrelevant, can't see the fields in seg flow...
    // batchData[imageData[currentIndex]].label.errorType = type;
    // batchData[imageData[currentIndex]].label.label_changed = true;
  };

  const errorLabelComplete = () => Object.prototype.hasOwnProperty.call(batchData[imageData[currentIndex]].label, "errorType");

  const selectActiveColor = (index: number) => {
    setActiveColor(index);
    // return focus to the painter
    canvasIntermediateRef.current.focus();
  };

  const setActiveColor = (index: number) => {
    if (index < batchData[imageData[currentIndex]].label.label_info.length)
      setActiveColor_(index);
    else if (index === 99) {
      batchData[imageData[currentIndex]].other_added = true;
      setActiveColor_(99);
      setBatchData(batchData);
    }
  };

  const toggleLabelInvalid = (index: number) => {
    const prevInvalidStatus = batchData[imageData[currentIndex]].label.label_info[index].invalid;
    batchData[imageData[currentIndex]].label.label_info[index].invalid = !prevInvalidStatus;

    setBatchData(batchData);

    // return focus to the painter
    canvasIntermediateRef.current.focus();
  };

  const setComment = (comm: string, setFocus = false) => {
    batchData[imageData[currentIndex]].checker_comment = comm;
    if (setFocus)
      canvasIntermediateRef.current.focus();
  };

  const missingLabelChecked = () => {
    batchData[imageData[currentIndex]].checking_status = "missing_label";

    setBatchData(batchData);
    saveCheckingStatus(batchData[imageData[currentIndex]]);
  };

  const keymap = {
    "nextImage": "alt+enter",
    "select1": "alt+1",
    "select2": "alt+2",
    "select3": "alt+3",
    "select4": "alt+4",
    "select5": "alt+5",
    "select6": "alt+6",
    "select7": "alt+7",
    "select8": "alt+8",
    "prevImage": "left",
    "currImage": "right",
  };

  const handlers = {
    "nextImage": (e: KeyboardEvent) => {
      e.preventDefault();
      if (labelComplete())
        nextImageEmpty();
    },
    "select1": (e: KeyboardEvent) => { e.preventDefault(); setActiveColor(0); },
    "select2": (e: KeyboardEvent) => { e.preventDefault(); setActiveColor(1); },
    "select3": (e: KeyboardEvent) => { e.preventDefault(); setActiveColor(2); },
    "select4": (e: KeyboardEvent) => { e.preventDefault(); setActiveColor(3); },
    "select5": (e: KeyboardEvent) => { e.preventDefault(); setActiveColor(4); },
    "select6": (e: KeyboardEvent) => { e.preventDefault(); setActiveColor(5); },
    "select7": (e: KeyboardEvent) => { e.preventDefault(); setActiveColor(6); },
    "select8": (e: KeyboardEvent) => { e.preventDefault(); setActiveColor(7); },
    "currImage": (e: KeyboardEvent) => {
      // pressing <right> on "current" image turns on mask
      e.preventDefault();
      if (imageShown === 0)
        canvasIntermediateRef.current.setShowMask(true);
      else
        setImageShown(0);
    },
    "prevImage": (e: KeyboardEvent) => {
      // pressing <left> on "current" image turns off mask before going to previous image
      e.preventDefault();
      if (imageShown !== 1) {
        if (canvasIntermediateRef.current.showMask)
          canvasIntermediateRef.current.setShowMask(false);
        else
          setImageShown(1);
      }
    }
  };

  // this weird code is to preload the images, instead of waiting for agent to click left
  const currImage = new Image();
  currImage.src = currURL;
  const prevImage = new Image();
  prevImage.src = prevURL;

  const currImageData = batchData[imageData[currentIndex]];

  return (
    <HotKeys keyMap={keymap} handlers={handlers}>
      <div className="animated fadeIn">
        <h1 style={{marginTop: "-15px"}}>
          {currImageData ? getDateString() : ""}
          <LiveCountComponent
            role="segmentation"
            loadCallback={(maxed) => {
              if (maxed) {
                alert("Today's maximum task count has been reached!");
                props.history.push("/");
              }
            }}
            ref={liveCountRef}
          />
        </h1>
        <div
          style={{
            marginBottom: "5px", fontWeight: "bold", fontSize: "1rem", maxWidth: "64%"
          }}>
          <div>
            <span style={{marginRight: "7%"}}>{ "Segmented by: " + (currImageData ? currImageData.seg_username : "") }</span>
            <span style={{marginRight: "7%"}}>{ "Segmented on: " + (currImageData ? currImageData.seg_datetime : "") }</span>
            <span style={{marginRight: "7%"}}>{ "Segments: " + (currImageData ? Object.keys(currImageData.annotation_data).length : "0") + " (+Other)" }</span>
          </div>
          <div>
            <span style={{marginRight: "18%"}}>{ "Area: " + (currImageData ? currImageData.area : "") }</span>
            <span style={{marginRight: "18%"}}>{ "Device ID: " + (currImageData ? currImageData.image_id.split("/")[0].split("-").slice(-1)[0] : "") }</span>
            <span>{"Added Weight: " + (currImageData ? currImageData.weight : "") + "g"}</span>
          </div>
        </div>
        <CanvasIntermediate
          prevImage={prevURL}
          currImage={currURL}
          maskImage={maskURL}
          displayImage={imageShown}
          activeColor={activeColor_}
          doSave={doSave}
          saveImage={saveImage}
          ref={canvasIntermediateRef}
          maskChanged={setMaskChanged}
        />
        <SegmentationLabelsContainer
          activeColor={activeColor_}
          approveFunction={approveMask}
          approveAndSaveFunction={saveImageCall}
          approveAndSaveDisable={!maskChanged}
          currentLabel={currImageData ? currImageData.label : null}
          errorLabelComplete={errorLabelComplete}
          ignoreFunction={ignoreMask}
          labelColor={labelColor}
          labelColorSolid={labelColorSolid}
          lastComment={currImageData ? currImageData.last_comment : null}
          missingLabelChecked={missingLabelChecked}
          nextButtonString={"Next Image"}
          nextImageEmpty={nextImageEmpty}
          resetUI={resetUI}
          selectActiveColor={selectActiveColor}
          setLabel={setErrorLabel}
          setComment={setComment}
          setViewMode={setViewMode}
          skipFunction={skipImage}
          toggleLabelInvalid={toggleLabelInvalid}
          topDistance={146}
          viewMode={viewMode}
        />

      </div>
      <div style={{clear: "both"}}></div>
    </HotKeys>
  );
};

export default SegmentationChecking;
