import React, { useState, useEffect } from "react";

function BatchDone (props: {location: { state: undefined | any }, history: any}): JSX.Element {
  const [batchID, setBatchID] = useState<number | null>(null);

  useEffect(() => {
    // Check if user entered this page by completing a batch
    if (props.history.location.state)
      setBatchID(props.history.location.state.batchID);
    else
      props.history.push("/");

  }, [batchID, props.history]);

  return (
    <div className="animated fadeIn">
      <h1>Awesome, you completed a batch!</h1>
      <input
        id="toBatches"
        className="btn btn-primary toBatches"
        type="button"
        value="Back to Outlet Overview"
        onClick={ () => props.history.push({pathname: "/"})}
      />
    </div>
  );
}

export default BatchDone;
