import AWS from "aws-sdk";
import $ from "jquery";
import { Task, Props, DVOutletPageState, DVImagesPageState } from "../views/DataValidity/_types";

export type SelectOption<Type = number> = {
  value: Type,
  label: string,
  isDisabled?: boolean
};

export const openDVOutletPage = (
  state: DVOutletPageState,
  history: { push: ({ pathname, state }) => void }
) => history.push({ pathname: "/dataValidity/outlet", state });

export const openDVImagesPage = (
  state: DVImagesPageState,
  history: { push: ({ pathname, state }) => void }
) => history.push({ pathname: "/dataValidity/images", state });

// TODO: put all of these in one place, and import from there
const s3 = new AWS.S3({
  accessKeyId: "AKIA4ZN2FXCSXF3DZJSD", secretAccessKey: "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", useAccelerateEndpoint: false, signatureVersion: "v4", region: "eu-central-1"
});
export const getImageURL = (image_key: string): string => s3.getSignedUrl("getObject", { Bucket: "kitroengine-s3", Key: image_key });

// handle DV task endpoint response
export const handleTaskResponse = (
  tasksData: Task[],
  setTasks: React.Dispatch<React.SetStateAction<Task[]>>,
  location: Props["location"]
) => setTasks((tasks_: Task[]) => {
  const final: Task[] = tasks_ ? [...tasks_] : [];

  if (tasksData.length !== 0) {
    tasksData.forEach((task) => {
      task.original_labels = task.labels.map((label) => ({ ...label }));
      task.original_errors = [...task.errors];
    });

    const taskIDs = final.map((task) => task.id);
    const tasksData_ = tasksData.filter((task) => !taskIDs.includes(task.id));
    final.push(...tasksData_);
  }
  else if (tasks_ !== null)
    alert("No more images found!");

  final.forEach((task) => {
    if (
      location.state &&
      location.state.mode !== "assess accuracy" &&
      location.state.dv_type !== "outliers" &&
      task.status === "pending"
    ) task.status = "skipped";
  });

  return final;
});

export const cloneTask = (task: Task): Task => JSON.parse(JSON.stringify(task));

export const addSpinner = (el, static_pos: boolean = false): void => {
  let spinner = el.children(".spinner");
  if (spinner.length && !spinner.hasClass("spinner-remove")) return null;
  !spinner.length && (spinner = $('<div class="spinner' + (static_pos ? "" : " spinner-absolute") + '"/>').appendTo(el));
  animateSpinner(spinner, "add");
};

export const removeSpinner = (el, complete: boolean = false): void => {
  const spinner = el.children(".spinner");
  spinner.length && animateSpinner(spinner, "remove", complete);
};

export const animateSpinner = (el, animation: string, complete: boolean = false): void => {
  if (el.data("animating")) {
    el.removeClass(el.data("animating")).data("animating", null);
    el.data("animationTimeout") && clearTimeout(el.data("animationTimeout"));
  }
  el.addClass("spinner-" + animation).data("animating", "spinner-" + animation);
  el.data("animationTimeout", setTimeout(function() { animation === "remove" && el.remove(); }, parseFloat(el.css("animation-duration")) * 1000));
};
