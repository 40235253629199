import React, { useState } from "react";
import Select from "react-select";

import ControlPanelSoftwareVersion from "./ControlPanelSoftwareVersion.js";
import ControlPanelDeviceUpdate from "./ControlPanelDeviceUpdate.js";


function ControlPanel () {

  const [controlOption, setControlOption] = useState({value: "", label: ""});

  const options = [
    { value: "softwareVersion", label: "Software Version" },
    { value: "deviceUpdate", label: "Device Update"},
  ];

  const getComponent = () => {
    switch (controlOption.value) {
      case "":
        return "";
      case "softwareVersion":
        return <ControlPanelSoftwareVersion />;
      case "deviceUpdate":
        return <ControlPanelDeviceUpdate/>;
      default:
        return "";
    }
  };

  return (
    <div className="animated fadeIn">
      <h1>Control Panel:</h1>
      <h3>Select control option:</h3>
      <Select className="select-dropdown-std" options={options} onChange={(selection) => setControlOption(selection)}/>
      <div
        style={{
          height: "0", borderTop: "1px solid black", margin: "10px 0"
        }}></div>
      <div>
        {getComponent()}
      </div>
    </div>
  );

}

export default ControlPanel;
