import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";

import { getFetch } from "../../utils/fetchUtils";
import { UserContext } from "../../context/userContext";

function ChangeRoleComponent({roleOptions, changeRole, currentRole}) {

  return ( <div className={"changeRoleDiv"}>
    <Select className={"profileDropdown " + (currentRole.value === "Labelling" ? "borderLabelling" : "borderSegmentation") } options={roleOptions} onChange={changeRole} value={currentRole}/>
    <div style={{"display": "inline", "paddingLeft": "15px"}}>Current task focus</div>

  </div>
  );
}

function UserInfoBoxComponent({content}) {

  return (<div className={"userInfoBoxContainer"}>
    <UserInfoBox type={"profile"} content={content}/>
    <UserInfoBox type={"labelling"} content={content.labellingInfo}/>
    <UserInfoBox type={"segmentation"} content={content.segmentationInfo}/>

  </div>);
}

function UserInfoBox({type, content}) {
  let title = "";
  let borderColor = "";

  const boxContent = [];

  switch (type) {
    case "profile":
      title = "Profile";
      borderColor = "#496063";
      boxContent.push(<a className={"contentBoxFlexElement"}>Agent: <b>{content.username}</b></a>);
      boxContent.push(<a className={"contentBoxFlexElement"}>Email: <b>{content.email}</b></a>);
      boxContent.push(<a className={"contentBoxFlexElement"}>Created on: <b>{content.signUpDate}</b></a>);
      boxContent.push(<a className={"contentBoxFlexElement"}>Kitron: <b>{content.isKitron ? content.kitronDate : "-"}</b></a>);
      break;
    case "labelling":
      title = "Image labelling";
      borderColor = "#6BB980";
      boxContent.push(<div className={"contentBoxFlexElement"}><a>Current Level:</a><a><b>{content.level}</b></a></div>);
      boxContent.push(<div className={"contentBoxFlexElement"}><a>Level <b>1</b>:</a><a><b>{content.level1Date ? content.level1Date : "-"}</b></a></div>);
      boxContent.push(<div className={"contentBoxFlexElement"}><a>Level <b>2</b>:</a><a><b>{content.level2Date ? content.level2Date : "-"}</b></a></div>);
      boxContent.push(<div className={"contentBoxFlexElement"}><a>Level <b>3</b>:</a><a><b>{content.level3Date ? content.level3Date : "-"}</b></a></div>);
      break;
    case "segmentation":
      title = "Image segmentation";
      borderColor = "#4B77A5";
      boxContent.push(<div className={"contentBoxFlexElement"}><a>Current Level:</a><a><b>{content.level}</b></a></div>);
      boxContent.push(<div className={"contentBoxFlexElement"}><a>Level <b>1</b>:</a><a><b>{content.level1Date ? content.level1Date : "-"}</b></a></div>);
      boxContent.push(<div className={"contentBoxFlexElement"}><a>Level <b>2</b>:</a><a><b>{content.level2Date ? content.level2Date : "-"}</b></a></div>);
      boxContent.push(<div className={"contentBoxFlexElement"}><a>Level <b>3</b>:</a><a><b>{content.level3Date ? content.level3Date : "-"}</b></a></div>);
      break;
    default:
      break;
  }

  return (<div className={"userInfoBox"} style={{"borderColor": borderColor}}>
    <h3 className='boxHeader' style={{"color": "#707070", "fontSize": "1.15em"}}>{title}</h3>
    <div className={"contentBoxFlex"}>{boxContent}</div>
  </div>);
}

function User () {
  const [dataChanged, setDataChanged] = useState(false);
  const roleOptions = [
    { value: "Labelling", label: "Labelling" },
    { value: "Segmentation", label: "Segmentation" },
  ];
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    if (dataChanged)
      setDataChanged(false);

  }, [dataChanged]);

  const changeRole = (arg) => {
    const newRole = arg.value;
    getFetch("/change-current-role", { method: "POST", data: { "role": newRole } })
      .then(() => {
        user.currentRole = newRole;
        setUser(user);
      });
  };

  return (
    <div className="animated fadeIn">
      <div className={"profileComponent"}>
        <ChangeRoleComponent roleOptions={roleOptions} changeRole={changeRole} currentRole={{ value: user.currentRole, label: user.currentRole }} />
        <UserInfoBoxComponent content={user}/>
      </div>
    </div>
  );

}

export default User;
