import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import $ from "jquery";

import SegmentationLabelsContainer from "./SegmentationLabelsContainer";
import CanvasIntermediate, { CanvasIntermediateHandle } from "./CanvasIntermediate";
import LiveCountComponent, { LiveCountComponentHandle } from "../LiveCountComponent/LiveCountComponent";
import { AllLabels } from "../../containers/LabelSelector/LabelSelector";

import { HotKeys } from "react-hotkeys";
import { getFetchResp } from "../../utils/fetchUtils.js";

import AWS from "aws-sdk";
import { SegTrasheventInfo } from "./types";

const s3 = new AWS.S3({
  accessKeyId: "AKIA4ZN2FXCSXF3DZJSD", secretAccessKey: "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", useAccelerateEndpoint: false, signatureVersion: "v4", region: "eu-central-1"
});

type LocationState = {
  batchData?: {
    keys: number[],
    trashevents: {
      [key: string]: SegTrasheventInfo
    }
  },
  fullLabelData?: AllLabels,
  checkBatch?: boolean,
  batchID?: number
};

interface SegmentationProps extends RouteComponentProps<any, any, LocationState> {
}

const Segmentation = (props: SegmentationProps) => {
  useEffect(() => {
    if (!props.history.location.state)
      props.history.push("/");

    if (props.history.location.state.batchData.keys.length === 0)
      props.history.push("/");
  }, [props.history]);

  const canvasIntermediateRef = useRef<CanvasIntermediateHandle>();
  const liveCountRef = useRef<LiveCountComponentHandle>();

  const getURL = (image_key: string) => {
    if (image_key === null)
      return null;
    return s3.getSignedUrl("getObject", { Bucket: "kitroengine-s3", Key: image_key });
  };

  const imageData = props.history.location.state.batchData.keys;
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [currentLabelIndex, setCurrentLabelIndex] = useState<number>(1);
  const [viewMode, setViewMode] = useState<string>("L");
  const [batchData, setBatchData] = useState(props.history.location.state.batchData.trashevents);
  const batchSize = imageData.length;
  const checkBatch = props.history.location.state.checkBatch;
  const [prevURL, setPrevURL] = useState(batchData[imageData[currentIndex]] ? getURL(batchData[imageData[currentIndex]].prev_image_id) : null);
  const [currURL, setCurrURL] = useState(batchData[imageData[currentIndex]] ? getURL(batchData[imageData[currentIndex]].image_id) : null);
  const [maskURL, setMaskURL] = useState(batchData[imageData[currentIndex]] ? getURL(batchData[imageData[currentIndex]].mask_id || null) : null);
  const [imageShown, setImageShown] = useState(0);
  const [activeColor_, setActiveColor_] = useState(0);
  const [doSave, setDoSave] = useState(false);
  const [maskChanged, setMaskChanged] = useState(false);

  const labelColor = { // these colors need to be in sync with CanvasIntermediate.js:labels (L30)
    0: "rgba(0, 255, 255, 0.4)",
    1: "rgba(255, 0, 0, 0.4)",
    2: "rgba(0, 255, 0, 0.4)",
    3: "rgba(0, 0, 255, 0.4)",
    4: "rgba(255, 255, 0, 0.4)",
    5: "rgba(255, 0, 255, 0.4)",
    6: "rgba(0, 0, 0, 0.4)",
    7: "rgba(255, 255, 255, 0.4)",
    99: "rgba(255, 0, 128, 0.4)"
  };
  const labelColorSolid = {
    0: "rgba(0, 255, 255, 0.8)",
    1: "rgba(255, 0, 0, 0.8)",
    2: "rgba(0, 255, 0, 0.8)",
    3: "rgba(0, 0, 255, 0.8)",
    4: "rgba(255, 255, 0, 0.8)",
    5: "rgba(255, 0, 255, 0.8)",
    6: "rgba(0, 0, 0, 0.8)",
    7: "rgba(255, 255, 255, 0.8)",
    99: "rgba(255, 0, 128, 0.8)",
  };

  const nextImage = (e?: any, status?: any) => {
    $("#root").css("background-color", "#fafafa");
    $(".success-checkmark").css("display", "block");
    $(".check-icon").show();

    setTimeout(function () {
      $(".check-icon").hide();
      $(".success-checkmark").css("display", "none");
    }, 1000);

    if (canvasIntermediateRef.current) {
      canvasIntermediateRef.current.zoomReset();
      canvasIntermediateRef.current.drawView();
      canvasIntermediateRef.current.focus();
    }

    const batchCompleted = currentIndex + 1 === batchSize;

    if (!batchCompleted) {
      const prevURL = getURL(batchData[imageData[currentIndex + 1]].prev_image_id);
      const currURL = getURL(batchData[imageData[currentIndex + 1]].image_id);
      const maskURL = getURL(batchData[imageData[currentIndex + 1]].mask_id || null);

      setCurrentIndex(currentIndex + 1);
      setPrevURL(prevURL);
      setCurrURL(currURL);
      setMaskURL(maskURL);
      setActiveColor_(0);
    }

    const postData = {"trashevent": batchData[imageData[currentIndex]]};

    getFetchResp("/segment-image", {
      api: global.non_vpc_url, data: postData, method: "POST"
    })
      .then((response) => {
        if (response.status >= 400) {
          alert("Something went wrong labelling this image!");
          props.history.push({pathname: "/", state: {}});
        }
        else
          setTimeout(() => { if (liveCountRef.current) liveCountRef.current.reload(); }, 1000);

      });

    if (batchCompleted) {
      if (checkBatch) {
        alert("Batch completed!");
        props.history.push("/");
      }
      else {
        props.history.push({
          pathname: "/batchDone",
          state: {batchID: 0}
        });
      }
    }
  };

  const nextImageEmpty = () => {
    if (currentIndex === currentLabelIndex)
      setCurrentLabelIndex(currentLabelIndex + 1);
    else
      setViewMode((currentIndex === currentLabelIndex - 1) ? "L" : "V");

    setCurrentIndex(currentIndex + 1);
  };

  const getDateString = () => {
    const myDate = new Date(batchData[imageData[currentIndex]].datetime);
    return myDate.toString().split("GMT")[0];
  };

  const labelComplete = () => {
    if (!(batchData[imageData[currentIndex]] && batchData[imageData[currentIndex]].label))
      return false;

    for (const label in batchData[imageData[currentIndex]].label.label_info) {
      const currentLabel = batchData[imageData[currentIndex]].label.label_info[label];
      if (currentLabel.percentage === 0 || currentLabel.id === 0 || currentLabel.value === 0 || currentLabel.label === "")
        return false;
    }

    return true;
  };

  const setErrorLabel = (type) => {
    // NOTE: i _think_ this is irrelevant, can't see the fields in seg flow...
    // batchData[imageData[currentIndex]].label.errorType = type;
    // batchData[imageData[currentIndex]].label.label_changed = true;
  };

  const errorLabelComplete = () => Object.prototype.hasOwnProperty.call(batchData[imageData[currentIndex]].label, "errorType");

  const selectActiveColor = (index: number) => {
    setActiveColor(index);
    // return focus to the painter
    canvasIntermediateRef.current.focus();
  };

  const setActiveColor = (index: number) => {
    if (index < batchData[imageData[currentIndex]].label.label_info.length)
      setActiveColor_(index);
    else if (index === 99) {
      batchData[imageData[currentIndex]].other_added = true;
      setActiveColor_(99);
      setBatchData(batchData);
    }
  };

  const toggleLabelInvalid = (index: number) => {
    const prevInvalidStatus = batchData[imageData[currentIndex]].label.label_info[index].invalid;
    batchData[imageData[currentIndex]].label.label_info[index].invalid = !prevInvalidStatus;

    setBatchData(batchData);

    // return focus to the painter
    canvasIntermediateRef.current.focus();
  };

  const saveImage = (png: string) => {
    batchData[imageData[currentIndex]].maskData = png;
    setBatchData(batchData);
    setDoSave(false);
    nextImage();
  };

  const saveImageCall = () => setDoSave(true);

  const noisyImageFunction = () => {
    const comment = prompt("Please enter comment:");
    if (!comment)
      return;
    batchData[imageData[currentIndex]].labeler_comment = comment;
    saveImageCall();
  };

  const missingLabel = () => {
    batchData[imageData[currentIndex]].labeler_comment = "Missing label";
    saveImageCall();
  };

  const keymap = {
    "nextImage": "alt+enter",
    "select1": "alt+1",
    "select2": "alt+2",
    "select3": "alt+3",
    "select4": "alt+4",
    "select5": "alt+5",
    "select6": "alt+6",
    "select7": "alt+7",
    "select8": "alt+8",
    "prevImage": "left",
    "currImage": "right",
  };

  const handlers = {
    "nextImage": (e: KeyboardEvent) => {
      e.preventDefault();
      if (labelComplete())
        nextImage();
    },
    "select1": (e: KeyboardEvent) => { e.preventDefault(); setActiveColor(0); },
    "select2": (e: KeyboardEvent) => { e.preventDefault(); setActiveColor(1); },
    "select3": (e: KeyboardEvent) => { e.preventDefault(); setActiveColor(2); },
    "select4": (e: KeyboardEvent) => { e.preventDefault(); setActiveColor(3); },
    "select5": (e: KeyboardEvent) => { e.preventDefault(); setActiveColor(4); },
    "select6": (e: KeyboardEvent) => { e.preventDefault(); setActiveColor(5); },
    "select7": (e: KeyboardEvent) => { e.preventDefault(); setActiveColor(6); },
    "select8": (e: KeyboardEvent) => { e.preventDefault(); setActiveColor(7); },
    "currImage": (e: KeyboardEvent) => {
      // pressing <right> on "current" image turns on mask
      e.preventDefault();
      if (imageShown === 0)
        canvasIntermediateRef.current.setShowMask(true);
      else
        setImageShown(0);
    },
    "prevImage": (e: KeyboardEvent) => {
      // pressing <left> on "current" image turns off mask before going to previous image
      e.preventDefault();
      if (imageShown !== 1) {
        if (canvasIntermediateRef.current.showMask)
          canvasIntermediateRef.current.setShowMask(false);
        else
          setImageShown(1);
      }
    }
  };

  // this weird code is to preload the images, instead of waiting for agent to click left
  const currImage = new Image();
  currImage.src = currURL;
  const prevImage = new Image();
  prevImage.src = prevURL;

  const currImageData = batchData[imageData[currentIndex]];

  return (
    <HotKeys keyMap={keymap} handlers={handlers}>
      <div className="animated fadeIn">
        <h1 style={{marginTop: "-15px"}}>
          {currImageData ? getDateString() : ""}
          <LiveCountComponent
            role="segmentation"
            loadCallback={(maxed) => {
              if (maxed) {
                alert("Today's maximum task count has been reached!");
                props.history.push("/");
              }
            }}
            ref={liveCountRef}
          />
        </h1>
        <div
          style={{
            marginBottom: "5px", fontWeight: "bold", fontSize: "1rem", maxWidth: "64%"
          }}>
          <span style={{marginRight: "18%"}}>{"Area: " + (currImageData ? currImageData.area : "")}</span>
          <span style={{marginRight: "18%"}}>{"Device ID: " + (currImageData ? currImageData.image_id.split("/")[0].split("-").slice(-1)[0] : "")}</span>
          <span>{"Added Weight: " + (currImageData ? currImageData.weight : "") + "g"}</span>
        </div>
        <CanvasIntermediate
          prevImage={prevURL}
          currImage={currURL}
          maskImage={maskURL}
          displayImage={imageShown}
          activeColor={activeColor_}
          doSave={doSave}
          saveImage={saveImage}
          ref={canvasIntermediateRef}
          maskChanged={(changed: boolean) => setMaskChanged(changed)}
        />
        <SegmentationLabelsContainer
          activeColor={activeColor_}
          currentLabel={currImageData ? currImageData.label : null}
          errorLabelComplete={errorLabelComplete}
          labelColor={labelColor}
          labelColorSolid={labelColorSolid}
          lastComment={currImageData ? currImageData.last_comment : null}
          missingLabel={missingLabel}
          nextButtonString={"Next Image"}
          nextImageEmpty={nextImageEmpty}
          nextImageFunction={maskChanged ? saveImageCall : null}
          noisyImageFunction={noisyImageFunction}
          resetUI={0}
          selectActiveColor={selectActiveColor}
          // setComment={setComment}   // TODO: once labelers are allowed to comment, just uncomment this
          setLabel={setErrorLabel}
          setViewMode={setViewMode}
          toggleLabelInvalid={toggleLabelInvalid}
          topDistance={146}
          viewMode={viewMode}
        />
      </div>
    </HotKeys>

  );
};

export default Segmentation;
