import React, { Component } from "react";
import $ from "jquery";

class ImageContainer extends Component {
  constructor(props) {
    super(props);

    this.hoverOverImage = this.hoverOverImage.bind(this);
    this.stopHoveringOverImage = this.stopHoveringOverImage.bind(this);
    this.scrollOnImage = this.scrollOnImage.bind(this);
    this.moveOnImage = this.moveOnImage.bind(this);
    this.toggleZoom = this.toggleZoom.bind(this);
    this.switchImage = this.switchImage.bind(this);

    this.state = {
      currentlyShownImage: 1,
      image0Zoom: 1,
      image1Zoom: 1,
      img0origin: 0,
      img1origin: 0,
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", (event) => {
      if (event.keyCode === 37) {
        event.preventDefault();
        this.switchImage(0);
      }
      if (event.keyCode === 39) {
        event.preventDefault();
        this.switchImage(1);
      }
    });
  }

  switchImage(i) {
    let img0zoom = this.state.image0Zoom;
    let img1zoom = this.state.image1Zoom;
    let img0Origin = this.state.img0origin;
    let img1Origin = this.state.img1origin;

    if (i === 0) {
      $("#currImage").css({"display": "none"});
      $("#prevImage").css({"display": "block"});
      img0zoom = img1zoom;
      img0Origin = img1Origin;
      $("#prevImage").css({"transform": "scale(" + img0zoom + ")"});
      $("#prevImage").css({"transform-origin": img0Origin + "%"});
    }
    else {
      $("#currImage").css({"display": "block"});
      $("#prevImage").css({"display": "none"});
      img1zoom = img0zoom;
      img1Origin = img0Origin;
      $("#currImage").css({"transform": "scale(" + img1zoom + ")"});
      $("#currImage").css({"transform-origin": img1Origin + "%"});

    }

    this.setState({
      currentlyShownImage: i,
      image0Zoom: img0zoom,
      image1Zoom: img1zoom,
      img0origin: img0Origin,
      img1origin: img1Origin,
    });
  }

  componentWillReceiveProps() {
    $("#currImage").css({"display": "block"});
    $("#prevImage").css({"display": "none"});

    this.setState({currentlyShownImage: 1});
  }

  hoverOverImage(e) {
    $(e.target).css({"transform": "scale(2.5)"});
    if (this.state.currentlyShownImage === 1)
      this.setState({image1Zoom: 2.5});

    else
      this.setState({image0Zoom: 2.5});

  }

  stopHoveringOverImage(e) {
    $(e.target).css({"transform": "scale(1)"});
    if (this.state.currentlyShownImage === 1)
      this.setState({image1Zoom: 1});

    else
      this.setState({image0Zoom: 1});

  }

  moveOnImage(e) {
    const origin = ((e.pageX) / $(e.target).width()) * 100 + "% " + (((e.pageY) / $(e.target).height()) - 0.3) * 100;
    $(e.target).css({"transform-origin": origin + "%"});

    if (this.state.currentlyShownImage === 1)
      this.setState({img1origin: origin});

    else
      this.setState({img0origin: origin});

  }

  toggleZoom(e) {
    if ( parseFloat($(e.target).css("transform").split(",")[0].split("(")[1]) > 1.5) {
      $(e.target).css({"transform": "scale(1)"});
      if (this.state.currentlyShownImage === 1)
        this.setState({image1Zoom: 1});

      else
        this.setState({image0Zoom: 1});

    }
    else {
      $(e.target).css({"transform": "scale(2.5)"});
      if (this.state.currentlyShownImage === 1)
        this.setState({image1Zoom: 2.5});

      else
        this.setState({image0Zoom: 2.5});

    }
  }

  scrollOnImage(e) {
    let zoomFactor = e.deltaY;

    let currentZoom = $(e.target).css("transform").split(",")[0].split("(")[1];
    if (!currentZoom)
      currentZoom = 1;

    zoomFactor = parseFloat(currentZoom) + zoomFactor / 20;

    if (currentZoom <= 1 && zoomFactor < 1)
      zoomFactor = 1;

    $(e.target).css({"transform": "scale(" + zoomFactor + ")"});
    if (this.state.currentlyShownImage === 1)
      this.setState({image1Zoom: zoomFactor});

    else
      this.setState({image0Zoom: zoomFactor});

  }

  render() {
    return (
      <div className="animated fadeIn imageContainerDiv">
        <img
          id="currImage"
          style={{display: "block"}}
          src={this.props.currImage}
          key={this.props.currImage ? this.props.currImage : 0}
          alt="Loading..."
          className="imageContainer photo"
          onMouseOver={this.hoverOverImage}
          onMouseOut={this.stopHoveringOverImage}
          onWheel={this.scrollOnImage}
          onMouseMove={this.moveOnImage}
          onClick={this.toggleZoom}
        />
        <img
          style={{display: "block"}}
          id="prevImage"
          src={this.props.prevImage}
          key={this.props.prevImage ? this.props.prevImage : 1}
          alt="Loading..."
          className="imageContainer photo"
          onMouseOver={this.hoverOverImage}
          onMouseOut={this.stopHoveringOverImage}
          onWheel={this.scrollOnImage}
          onMouseMove={this.moveOnImage}
          onClick={this.toggleZoom}
        />
        <h2 className={"top-right"}>{this.state.currentlyShownImage === 1 ? "Now" : "Previous"}</h2>
      </div>
    );
  }
}

export default ImageContainer;
