import React, { useEffect, useMemo, useState, useContext } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Button, Popper, ClickAwayListener, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import GetApp from "@material-ui/icons/GetApp";

import { UserContext } from "../../context/userContext";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import moment from "moment";

import { FilterBar } from "./FilterBar";
import { TasksCountTable, TasksCountBarChart, TasksCountTooltip } from "./TasksCount";
import { WorkingTimeTable, WorkingTimeTooltip } from "./WorkingTime";
import { LabellingAccuracyTable, LabellingAccuracyLineChart, LabellingAccuracyTooltip } from "./LabellingAccuracy";
import { AgentInfoCards } from "./AgentInfo";
import { getFetchResp } from "../../utils/fetchUtils";
import { FilterValues, AgentOption } from "./_types";

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    style={{margin: "1em 0", overflow: "auto"}}
    {...other}
  >
    {value === index && children}
  </div>
);

const PerformanceReport = () => {
  const { user } = useContext(UserContext);
  const today: Date = new Date();
  const lastDayMonth: Date = new Date();
  lastDayMonth.setDate(1);
  lastDayMonth.setHours(-1);
  const selectedUsernamesState = useState<AgentOption[] | null>(
    user.labellingInfo.level < 3
      ? [{
        id: user.id,
        label: user.username,
        value: user.username,
        company: user.company,
        user_level: user.labellingInfo.level
      }]
      : null);
  const selectedFilters = useState<FilterValues>({level: null, company: null});
  const [startDate, setStartDate] = useState<string>(
    new Date(today.getFullYear(), today.getMonth() - 1, 2)
      .toISOString()
      .substr(0, 10)
  );
  const [endDate, setEndDate] = useState<string>(
    lastDayMonth.toISOString().substr(0, 10)
  );
  const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false);
  const [downloadType, setDownloadType] = useState<string>("agent");
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);

  const agentIDs = useMemo<string[]>(() => (selectedUsernamesState[0] || []).map((user) => user.id), [selectedUsernamesState]);
  const [currentTab, setCurrentTab] = useState(0);

  // we need to remove the "overflow: hidden" from <body> and put it back on unmount
  useEffect(() => {
    document.getElementsByTagName("body")[0].style.overflowY = "auto";
    return () => { document.getElementsByTagName("body")[0].style.overflowY = "hidden"; };
  });

  const downloadReport = async () => {
    const { company, level }: FilterValues = selectedFilters[0];

    const resp = await getFetchResp("/agent-performance/download-report?" +
        `agent=${agentIDs}&` +
        `start_date=${startDate}&` +
        `end_date=${endDate}&` +
        (level ? ("level=" + level.map((item) => item.value) + "&") : "") +
        (company ? ("company=" + company.map((item) => item.label)) + "&" : "") +
        `per_day=${downloadType === "day_and_agent"}`,
    {
      headers: { "Accept": "application/octet-stream"}, data: undefined, api: undefined
    });

    if (resp.status === 404) {
      alert("There is no data for the selected filters.");
      return;
    }

    if (!resp.ok) {
      alert("Something went wrong.");
      return;
    }

    const timestamp = moment().format("YYYY_MM_DD_HH_mm_ss");

    const blob = await resp.arrayBuffer();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const a = document.createElement("a");
    a.href = url;
    a.download = `agents_performance_report_${timestamp}.xlsx`;
    document.body.appendChild(a);
    a.click();
    a.parentNode.removeChild(a);
  };

  return (
    <div className="animated fadeIn">
      <h1 style={{float: "left"}}>Performance Report</h1>
      <ClickAwayListener onClickAway={() => setIsPopperOpen(false)}>
        <div style={{float: "right", marginBottom: "5px"}}>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={(event) => { setAnchorEl(event.currentTarget); setIsPopperOpen(!isPopperOpen); setDownloadType("agent"); }}
          >
            {<GetApp></GetApp>}
          </Button>

          <Popper
            anchorEl={anchorEl}
            open={isPopperOpen}
            style={{
              backgroundColor: "white", border: "2px solid black", borderRadius: "10px", padding: "10px"
            }}
          >
            <FormControl component="fieldset">
              <FormLabel component="legend">Show details per</FormLabel>
              <RadioGroup value={downloadType} onChange={(event) => setDownloadType(event.target.value)}>
                <FormControlLabel value="agent" control={<Radio color="primary" />} label="Agent" />
                <FormControlLabel value="day_and_agent" control={<Radio color="primary" />} label="Day and Agent" />
              </RadioGroup>
            </FormControl>

            <Button
              variant="contained"
              color="primary"
              onClick={() => { downloadReport(); setIsPopperOpen(false); }}
              style={{display: "block"}}
            >
                Download Report
            </Button>

          </Popper>
        </div>
      </ClickAwayListener>

      <FilterBar
        selectedUsernamesState={selectedUsernamesState}
        selectedFilters={selectedFilters}
        startDateState={[startDate, setStartDate]}
        endDateState={[endDate, setEndDate]}
      />

      <div style={{flexGrow: 1, marginTop: "1em"}}>
        <Paper square>
          <Tabs
            value={currentTab}
            onChange={(event, newValue) => setCurrentTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Tasks Count" />
            <Tab label="Working Time" />
            <Tab label="Labelling Accuracy" />
            <Tab label="Agent Info" />
          </Tabs>
        </Paper>
        <TabPanel value={currentTab} index={0}>
          <h2>
            Tasks Count
            <sup>
              <InfoOutlinedIcon data-tip data-for="tasksCountTableTip" style={{ fontSize: 16, marginLeft: "5px"}} />
              <TasksCountTooltip />
            </sup>
          </h2>
          {agentIDs.length ? <TasksCountBarChart agentIDs={agentIDs} startDate={startDate} endDate={endDate} /> : null }
          {agentIDs.length ? <TasksCountTable agentIDs={agentIDs} startDate={startDate} endDate={endDate} /> : <p>Select agents.</p> }
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <h2>
            Working Time
            <sup>
              <InfoOutlinedIcon data-tip data-for="workingTimeTableTip" style={{ fontSize: 16, marginLeft: "5px"}} />
              <WorkingTimeTooltip />
            </sup>
          </h2>
          {agentIDs.length ? <WorkingTimeTable agentIDs={agentIDs} startDate={startDate} endDate={endDate} /> : <p>Select agents.</p> }
        </TabPanel>
        <TabPanel value={currentTab} index={2}>
          <h2>
            Labelling Accuracy
            <sup>
              <InfoOutlinedIcon data-tip data-for="labellingAccuracyTableTip" style={{ fontSize: 16, marginLeft: "5px"}} />
              <LabellingAccuracyTooltip />
            </sup>
          </h2>
          {agentIDs.length ? <LabellingAccuracyLineChart agentIDs={agentIDs} startDate={startDate} endDate={endDate} /> : null}
          {agentIDs.length ? <LabellingAccuracyTable agentIDs={agentIDs} startDate={startDate} endDate={endDate} /> : <p>Select agents.</p>}
        </TabPanel>
        <TabPanel value={currentTab} index={3}>
          {agentIDs.length ? <AgentInfoCards agentIDs={agentIDs} /> : <div className='report-cards-container'>Select agents.</div>}
        </TabPanel>
      </div>

    </div>
  );
};

export default PerformanceReport;
