import React from "react";


const TrainingSectionLabelling: React.FC<{
  location: { state: undefined | any },
  history: any
}> = (props) => (
  <div className="animated fadeIn">
      Welcome to the Labelling section!
  </div>
);

export default TrainingSectionLabelling;
