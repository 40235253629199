import React, { useState, useRef, useEffect, useMemo, forwardRef, useImperativeHandle, useCallback } from "react";

import usePaintCanvas from "./Canvas";
import { sizesType } from "./types";

type CanvasIntermediateParams = {
  activeColor: number,
  currImage: string,
  displayImage: number,
  doSave: boolean,
  maskChanged?: (changed: boolean) => void,
  maskImage: string,
  prevImage: string,
  saveImage: (png: string) => void,
};

export type CanvasIntermediateHandle = {
  drawView: () => void,
  eraseMask: () => void,
  focus: () => void,
  setShowMask: (show: boolean) => void,
  showMask: boolean,
  zoomReset: () => void,
};

const CanvasIntermediate: React.ForwardRefRenderFunction<CanvasIntermediateHandle, CanvasIntermediateParams> = ({ activeColor, doSave, saveImage, displayImage, currImage, prevImage, maskImage, maskChanged }, ref) => {
  const img = useRef(null);
  const [toolProps, setToolProps] = useState({ color: "#0ff", width: 10 });
  const [sizes, setSizes] = useState<sizesType>([100, 100, 100, 100]);
  const zoomMin = sizes[0] / sizes[2];
  const zoomMax = 4;
  const zoomStep = 1.25;
  const [zoom, setZoom] = useState(zoomMin); //useState(1);  // 780 / 3280
  const [brushPreview, setBrushPreview] = useState(true);
  const [showMask, setShowMask] = useState(true);

  const zoomIn = () => setZoom(Math.min(zoom * zoomStep, zoomMax));
  const zoomOut = () => setZoom(Math.max(zoom / zoomStep, zoomMin));
  // set to zoomMin, but with current `sizes`, not captured ones :D
  const zoomReset = useCallback(() => setZoom(sizes[0] / sizes[2]), [sizes]);

  const incBrush = () => setToolProps({ ...toolProps, width: Math.min(toolProps.width + 15, 300) });
  const decBrush = () => setToolProps({ ...toolProps, width: Math.max(toolProps.width - 15, 10) });

  const lastWidth = useRef([0, 0]); // stores last view and img width
  const lastMask = useRef(null);

  /////////////////////////////////////////////////////////////////////
  // stuff related to labels; actually, the labels are of no interest
  // to the Canvas component, just the color to paint with.

  const labels = useMemo(() => ({
    0: "#0ff",
    1: "#f00",
    2: "#0f0",
    3: "#00f",
    4: "#ff0",
    5: "#f0f",
    6: "#000",
    7: "#fff",
    99: "#ff0080",
  }), []);

  // use the hook, provide it with all inputs
  const canvas = useRef(null);
  const view = useRef(null);

  const {
    doUndo,
    doRedo,
    canUndo,
    canRedo,
    wasChanged,
    onClick,
    onMove,
    eraseMask,
    getMaskAsPNG,
    drawView
  } = usePaintCanvas({
    canvas: canvas.current,
    view: view.current,
    img: img.current,
    toolProps,
    showMask,
    sizes,
    zoom,
    zoomIn,
    zoomOut,
    brushPreview,
    incBrush,
    decBrush,
  });

  useEffect(() => {
    setToolProps((prevState) => ({ ...prevState, color: labels[activeColor] }));
  }, [activeColor, labels]);

  useEffect(() => {
    if (doSave) {
      saveImage(getMaskAsPNG());
      eraseMask();
    }
  }, [doSave, eraseMask, getMaskAsPNG, saveImage]);

  useEffect(() => {
    if (maskChanged)
      maskChanged(wasChanged);
  }, [wasChanged, maskChanged]);

  /////////////////////////////////////////////////////////////////////
  // keyboard hotkeys

  const onKeyPress = ({ nativeEvent }) => {
    if (nativeEvent.key === "[")
      decBrush();
    else if (nativeEvent.key === "]")
      incBrush();
    else if ((nativeEvent.key === "+") || (nativeEvent.key === "="))
      zoomIn();
    else if (nativeEvent.key === "-")
      zoomOut();
    else if (nativeEvent.key === "0")
      zoomReset();
    else if ((nativeEvent.key === "z") || (nativeEvent.key === "Z"))
      doUndo();
    else if ((nativeEvent.key === "y") || (nativeEvent.key === "Y"))
      doRedo();
    else if ((nativeEvent.key === "m") || (nativeEvent.key === "M"))
      setShowMask(!showMask);
    else if ((nativeEvent.key === "b") || (nativeEvent.key === "B"))
      setBrushPreview(!brushPreview);
  };

  /////////////////////////////////////////////////////////////////////
  // allow parent to use some of the internal functions
  useImperativeHandle(ref, () => ({
    zoomReset,
    focus: () => segDivRef.current ? segDivRef.current.focus() : null,
    drawView,
    showMask,
    setShowMask,
    eraseMask,
    wasChanged
  }), [showMask, wasChanged, drawView, eraseMask, zoomReset]);

  const segDivRef = useRef<HTMLDivElement>();
  const runWithRefocus = (fn: () => void) => {
    fn();
    segDivRef.current.focus();
  };

  const imgLoaded = () => {
    segDivRef.current.focus();

    if ((lastWidth.current[0] !== img.current.width) ||
        (lastWidth.current[1] !== img.current.naturalWidth)) {
      setZoom(img.current.width / img.current.naturalWidth);
      lastWidth.current = [img.current.width, img.current.naturalWidth];
    }
    setSizes([img.current.width, img.current.height, img.current.naturalWidth, img.current.naturalHeight]);

    if (maskImage !== lastMask.current) {
      lastMask.current = maskImage;
      if (maskImage) {
        fetch(maskImage)
          .then((resp) => resp.blob())
          .then((blob) => {
            if (blob.size === 0)
              return;
            const reader = new FileReader();
            reader.onload = () => {
              const img = new Image();
              img.onload = () => { canvas.current?.getContext("2d").drawImage(img, 0, 0); };
              img.src = reader.result as string;
            };
            reader.readAsDataURL(blob);
          });
      }
    }
  };

  /////////////////////////////////////////////////////////////////////
  // finally, render the app...
  //
  // note that the image, and the two canvases, that are provided to
  // the hook sit alone in a div `segmentation-canvas-div` on their own; the div has
  // set width to make sure the image and canvases get limited as well.
  // this is to make sure that the canvases (which get, as DOM elements,
  // stretched over the div as well) correspond to the image, and that
  // the aspect ratio is thus correct.
  // for that very reason, the <div/> containing the <img/> should not
  // contain anything else!
  //
  // the `tabIndex="1"` on App's <div/> is required to make sure it
  // can catch keyboard events.

  return (
    <>
      <div ref={segDivRef} className="segmentation-div" tabIndex={1} onKeyPress={onKeyPress}>
        <div className={"imageText"}>{displayImage === 0 ? "Current Image" : "Previous Image"}</div>
        <div className="segmentation-canvas-div">
          <img src={displayImage === 0 ? currImage : prevImage} ref={img} onLoad={imgLoaded} className="draw-image hidden" alt="to draw over..." />
          <canvas id="test" ref={canvas} className="draw-canvas d-none"></canvas>
          <canvas
            ref={view}
            className="draw-canvas"
            onMouseDown={onClick}
            onMouseUp={onClick}
            onMouseOut={onClick}
            onMouseMove={onMove}
            onContextMenu={onClick}
            onWheel={onClick}
          >HTML5 canvas not supported.</canvas>
        </div>
      </div>
      <div id="segmentation-controls">
        <button onClick={() => runWithRefocus(zoomIn)}>Zoom In (+)</button>
        <button onClick={() => runWithRefocus(zoomOut)}>Zoom Out (-)</button>
        <button onClick={() => runWithRefocus(zoomReset)}>Zoom Reset (0)</button>
        <button onClick={() => runWithRefocus(() => setShowMask(!showMask))}>{showMask ? "\u2713" : "\u2715"} Show Mask (m)</button>
        <button onClick={() => runWithRefocus(doUndo)} disabled={!canUndo}>Undo (z)</button>
        <button onClick={() => runWithRefocus(doRedo)} disabled={!canRedo}>Redo (y)</button>
        <button onClick={() => runWithRefocus(decBrush)}>Dec Brush ([)</button>
        <button onClick={() => runWithRefocus(incBrush)}>Inc Brush (])</button>
      </div>
    </>
  );
};

export default forwardRef(CanvasIntermediate);
