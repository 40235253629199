import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./App.scss";

// Containers
import { DefaultLayout } from "./containers";
// Pages
import { Page404, Page500 } from "./views/Pages";
// Context
import { UserContext } from "./context/userContext";
import { getFetch } from "./utils/fetchUtils";

// import { renderRoutes } from 'react-router-config';

import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import { signOut } from "aws-amplify/auth";

const signUpConfig = {hiddenDefaults: ["phone_number"]};

const formFields = {
  signUp: {
    username: { order: 1 },
    email: {
      label: "Email",
      isRequired: true,
      order: 2
    }
  },
};

class App extends Component {
  setUser = (user) => {
    this.setState({ user: user });
  };

  state = {
    user: null,
    setUser: this.setUser
  };

  componentDidMount() {
    this.loadUserData();
  }

  loadUserData() {
    getFetch("/get-user-data", {})
      .then((data) => {
        this.setUser(data);
      })
      .catch((error) => {
        alert(error);
        signOut();
        window.location.reload();
      });
  }

  render() {
    const value = {
      user: this.state.user,
      setUser: this.setUser
    };

    if (!this.state.user)
      return <div>Loading.. please wait!</div>;


    return (
      <UserContext.Provider value={value}>
        <HashRouter>
          <Switch>
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <Route path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </HashRouter>
      </UserContext.Provider>
    );
  }
}

export default withAuthenticator(App, {
  formFields,
  signUpConfig
});
