import React, {useContext } from "react";
import { getDateTime } from "../../utils/dateUtils.js";
import useFetch from "../../utils/useFetch";
import "./TrainingSection.scss";
import { getFetch } from "../../utils/fetchUtils";
import { UserContext } from "../../context/userContext";

type Quiz = {
  active: Boolean,
  id: Number,
  passing_mark: Number,
  u_score: Number,
  data: {
    description: String,
    name: String,
    passing_mark: Number,
    tolerance: number
  }
};

const AllQuizzesBox = ({fn}): JSX.Element => {
  const quizzes = useFetch<Array<Quiz>>("/training/quiz");
  if (quizzes.error)
    return <p>Failed to load: {quizzes.error}</p>;
  if (!quizzes.loaded)
    return <p>Loading...</p>;
  return <>
    {quizzes.data.map((q) => <button key={q.id.toString()} type="button" className="btn btn-primary quiz-button" onClick={() => fn(q)}>{q.data.name}</button>
    )}
  </>;
};

const TrainingSection: React.FC<{
  location: { state: undefined | any },
  history: any
}> = (props) => {
  const adept_sheet: string = "https://docs.google.com/presentation/d/1AdXmijYobDg91XRNfJjHqJ5ewiTbwEOXSgIwOd6yY3c";
  const hitech_sheet: string = "https://docs.google.com/presentation/d/13EyQcVeT23a14tdJo1soBXNIZlFUqWD8J6HCPxkw9Z4";

  const { user } = useContext(UserContext);
  const fullLabelData = useFetch("/get-labels?active=true");

  const startQuiz = async (quiz: Quiz) => {
    const quiz_questions = await getFetch(`/training/quiz/${quiz.id}/question?localtime=${getDateTime()}`, {});
    props.history.push({
      pathname: "/training/quiz",
      state: {
        quiz: quiz,
        quiz_questions: quiz_questions,
        fullLabelData: fullLabelData.loaded ? fullLabelData.data.allLabels : {},
        mode: "quiz"
      }
    });
  };

  const generateManualLinks = (): JSX.Element[] => {
    const response: JSX.Element[] = [];

    switch (user.company) {
      case "KITRO":
        response.push(<a href={adept_sheet} target={"_blank"} style={{padding: "10px", fontSize: "20px"}} rel="noreferrer">Adept Labelset Introduction</a>);
        response.push(<a href={hitech_sheet} target={"_blank"} style={{padding: "10px", fontSize: "20px"}} rel="noreferrer">HiTech Labelset Introduction</a>);
        break;
      case "Adept":
        response.push(<a href={adept_sheet} target={"_blank"} style={{padding: "10px", fontSize: "20px"}} rel="noreferrer">Labelset Introduction</a>);
        break;
      case "HiTech":
        response.push(<a href={hitech_sheet} target={"_blank"} style={{padding: "10px", fontSize: "20px"}} rel="noreferrer">Labelset Introduction</a>);
        break;
      default:
        response.push(<a style={{padding: "10px", fontSize: "20px"}}>Something went wrong!</a>);
        break;
    }

    return response;
  };

  return (
    <div className="animated fadeIn">
      <h1 style={{textAlign: "center", fontSize: "32px"}}>Welcome to the training section!</h1>
      <div className={"trainingDiv"}>
        <button disabled={true} type="button" className="btn btn-outline-dark trainingButton" onClick={() => props.history.push({pathname: "/training/faq"})}>Segmentation<div style={{fontSize: "18px"}}>Coming soon!</div></button>
        <div className="training-box">
          <h3 style={{textAlign: "center", fontWeight: "bold"}}>Labelling Quizzes</h3>
          <div className="quiz-buttons-box">
            <AllQuizzesBox fn={startQuiz} />
          </div>
        </div>
        <div className="trainingButton" style={{textAlign: "center"}}>See the labelling manual:
          <div style={{textAlign: "center"}}>
            {generateManualLinks()}
          </div>
        </div>
        <button disabled={true} type="button" className="btn btn-outline-dark trainingButton" onClick={() => props.history.push({pathname: "/training/tutorial"})}>Labelling Video Tutorial<div style={{fontSize: "18px"}}>Coming soon!</div></button>
      </div>
    </div>
  );
};

export default TrainingSection;
