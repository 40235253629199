import React from "react";


const TrainingSectionTutorial: React.FC<{
  location: { state: undefined | any },
  history: any
}> = (props) => (
  <div
    className="animated fadeIn" style={{
      fontSize: "22px", fontWeight: "bold", textAlign: "center"
    }}>
      Welcome to the Video Tutorial!
    <br/>
    <video width="75%" height="auto" controls style={{margin: "10px 12.5%"}}>
      <source src="https://s3.eu-central-1.amazonaws.com/kitro-icons/labeling-dashboard-tutorial/Image+Labellers+KITRO+Tutorial.mp4" type="video/mp4"/>
            Your browser does not support the video tag.
    </video>
  </div>
);

export default TrainingSectionTutorial;
