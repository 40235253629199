/* eslint-disable react-hooks/exhaustive-deps */

import React, {useState, useEffect, useMemo, forwardRef, useImperativeHandle, useRef, useCallback} from "react";
import useFetch from "../../utils/useFetch";
import useKeyPress from "../../utils/useKeyPress";
import Select from "react-select";
import { SelectOption } from "../../utils/helpers";

export type Label = {
  id: number,
  avoidable: string,
  class: string,
  group: string,
  subgroup: string,
  description: string,
  transformed: string | null,
  untransformed: string | null
};

export type AllLabels = {
  [key: number]: Label
}

type FilteredLabel = {
  id: number,
  str: string
};

type LabelEditingState = {
  label: number,
  percentage: number,
  transformed: string,
  editing: boolean
};

type ErrorEditingState = {
  error: number
};

export type LabelSelection = {
  id: number,
  percentage: number,
  transformed: boolean
};

export const printLabel = (label: Label, from_level: number = 0, sep: string = "/"): string => {
  switch (from_level) {
    case -1:
      return `${label.avoidable}${sep}${label.class}${sep}${label.group}${sep}${label.subgroup}${sep}`;
    case 0:
      return `${label.avoidable}${sep}${label.class}${sep}${label.group}${sep}${label.subgroup}${sep}${label.description}`;
    case 1:
      return `${label.class}${sep}${label.group}${sep}${label.subgroup}${sep}${label.description}`;
    case 2:
      return `${label.group}${sep}${label.subgroup}${sep}${label.description}`;
    case 3:
      return `${label.subgroup}${sep}${label.description}`;
    case 4:
      return `${label.description}`;
    default:
      return "";
  }
};

const compareLabelValue = (a: string, b: string): number => {
  if ((a === "other") && (b !== "other"))
    return -1;
  if ((b === "other") && (a !== "other"))
    return 1;
  return a.localeCompare(b);
};

export const compareLabels = (a: Label, b: Label): number => {
  const elements = ["avoidable", "class", "group", "subgroup", "description"];
  for (const el in elements) {
    const res = compareLabelValue(a[elements[el]], b[elements[el]]);
    if (res)
      return res;
  }
  return 0;
};

const getFilteredLabels = (labels: Label[], filterStr: string): FilteredLabel[] => {
  const satisfiesFilter = (s: string, f: string): boolean => s.search(f) >= 0;
  // const satisfiesFilter = (s: string, f: string): boolean => s.startsWith(f);

  let res = [];
  if (filterStr) {
    for (const k in labels) {
      const label = labels[k];
      if (satisfiesFilter(label.avoidable, filterStr))
        res.push({id: label.id, str: printLabel(labels[k], 0)});
      else if (satisfiesFilter(label.class, filterStr))
        res.push({id: label.id, str: printLabel(labels[k], 1)});
      else if (satisfiesFilter(label.group, filterStr))
        res.push({id: label.id, str: printLabel(labels[k], 2)});
      else if (satisfiesFilter(label.subgroup, filterStr))
        res.push({id: label.id, str: printLabel(labels[k], 3)});
      else if (satisfiesFilter(label.description, filterStr))
        res.push({id: label.id, str: printLabel(labels[k], 4)});
    }
  }
  else
    res = Object.keys(labels).map((k) => ({id: labels[k].id, str: printLabel(labels[k])}));

  return res;
};

const LabelButton = ({str, selected, active, onClick}) => <div className={`lli-label-button ${selected ? "lli-label-button-selected" : ""}`} onClick={onClick}>{str}</div>;

const isInFocus = (ref: React.MutableRefObject<any>): boolean => (ref.current && (document.activeElement === ref.current));

type LabelEditorParams = {
  label: LabelEditingState,
  allLabels: Label[],
  reset: number,
  setter: {
    label: (l: number) => void,
    percentage: (p: string) => void,
    transformed: (t: string) => void,
    editing: (e: boolean) => void
  },
  confirmLabelCbk: () => void,
  removeCbk: () => void,
  labelByID: (labelId: number) => Label
}

const LabelEditor = ({label, allLabels, reset, setter, confirmLabelCbk, removeCbk, labelByID}: LabelEditorParams) => {
  const [filterStr, setFilterStr] = useState("");
  const filteredLabels = useMemo<FilteredLabel[]>(() => getFilteredLabels(allLabels, filterStr), [allLabels, filterStr]);
  const [transformedTags, setTransformedTags] = useState([]);
  const filterEditRef = useRef<HTMLInputElement>(null);
  const filterEditInFocus = isInFocus(filterEditRef);
  const percentageEditRef = useRef<HTMLInputElement>(null);
  const transformedSelectRef = useRef<HTMLSelectElement>(null);
  const arrowDown = useKeyPress("ArrowDown");
  const arrowUp = useKeyPress("ArrowUp");
  const enter = useKeyPress("Enter");
  const selected = useRef<number>(-1);

  useEffect(() => {
    setFilterStr("");
    setTransformedTags([]);
    selected.current = -1;
  }, [reset]);

  useEffect(() => {
    if (label.editing && filterEditRef.current)
      filterEditRef.current.focus();
  }, [label.editing]);

  const confirmLabel = useCallback(() => {
    if (label.label === 0)
      return;
    if ((label.percentage > 100) || (label.percentage <= 0)) {
      if (percentageEditRef.current)
        percentageEditRef.current.style.border = "2px solid red";
      return;
    }
    else if (percentageEditRef.current)
      percentageEditRef.current.style.border = "";
    setter.editing(false);
    confirmLabelCbk();
  }, [confirmLabelCbk, label.label, label.percentage, setter]);

  const setSelectedLabel = useCallback((label: Label) => {
    setter.label(label.id);
    const tts = [];
    if (label.untransformed)
      tts.push(label.untransformed);
    if (label.transformed)
      tts.push(label.transformed);
    setTransformedTags(tts);
    setter.transformed(tts.length ? tts[0] : null);
    if (percentageEditRef.current)
      percentageEditRef.current.select();
  }, [setter]);

  useEffect(() => {
    if (!label.editing)
      return;

    if (arrowUp && filterEditInFocus && (selected.current > 0))
      selected.current -= 1;
    if (arrowDown && filterEditInFocus && (selected.current < filteredLabels.length - 1))
      selected.current += 1;
    if (enter) {
      // enter selects highlighted label, moves to next edit, and confirms when on the last edit
      if (filterEditInFocus && (selected.current >= 0) && (selected.current < filteredLabels.length))
        setSelectedLabel(labelByID(filteredLabels[selected.current].id));
      else if (isInFocus(percentageEditRef)) {
        if (transformedTags.length > 1)
          transformedSelectRef.current.focus();
        else
          confirmLabel();
      }
      else if (isInFocus(transformedSelectRef))
        confirmLabel();
    }
  }, [arrowDown, arrowUp, enter]);

  const filterChanged = (flt: string) => {
    selected.current = -1;
    setFilterStr(flt);
  };

  const resetFilter = () => {
    filterChanged("");
    if (filterEditRef.current)
      filterEditRef.current.focus();
  };

  const updateLabel = (label_obj) => {
    setter.editing(true);
    filterChanged(label_obj.description);
  };

  return <div className="label-list-item">
    <div className="lli-label-flex" hidden={label.editing}>
      {label.label
        ? <>
          <div className="lli-label" onClick={() => updateLabel(labelByID(label.label))}>
            <p className="lli-label-name">{printLabel(labelByID(label.label))} {(label.transformed ? `(${label.transformed}) ` : "") + `${label.percentage} %`}</p>
          </div>
          <div className="lli-remove" onClick={removeCbk}>X</div>
        </>
        : <div className="lli-add-label" onClick={() => setter.editing(true)}>+ Add Label</div>
      }
    </div>
    <div hidden={!label.editing}>
      <div className="lli-selectors">
        <input className="lli-filter" type="text" ref={filterEditRef} value={filterStr} onChange={(e) => filterChanged(e.target.value)} />
        <div className="lli-clear-filter" onClick={resetFilter}>x</div>
        <input className="lli-percentage" type="number" ref={percentageEditRef} value={label.percentage} onChange={(e) => setter.percentage(e.target.value)} />
        {transformedTags.length > 0 &&
          <select ref={transformedSelectRef} value={label.transformed} onChange={(e) => setter.transformed(e.target.value)} disabled={transformedTags.length === 1} style={{marginLeft: "0.5em"}}>
            {transformedTags.map((tr, i) => <option value={tr} key={i}>{tr}</option>)}
          </select>}
        <div className="btn-primary lli-confirm" onClick={confirmLabel}>Confirm</div>
      </div>
      {filterEditInFocus || (label.label === 0)
        ? <div className="lli-label-container" style={{maxHeight: "15em"}}>
          {filteredLabels.map((k, i) => <LabelButton str={k.str} selected={i === selected.current} active={k.id === label.label} key={k.id} onClick={() => setSelectedLabel(labelByID(k.id))} />)}
        </div>
        : <div className="lli-label-container-selected"><b>{printLabel(labelByID(label.label))}</b></div>
      }
    </div>
  </div>;
};

type ErrorInfo = {
  id: number,
  error_name: string,
  translation: {
    en: string,
    de: string,
    fr: string
  },
  data: {
    has_error_label?: boolean,
    num_labels?: number,
    min_labels?: number,
    min_weight?: number
  }
};

type ErrorEditorParams = {
  labels: LabelEditingState[],
  error: ErrorEditingState,
  errors: ErrorInfo[],
  errorLabelId: number,
  currentImageData?: any,
  reset: number,
  setter: {
    error: (e: number) => void
  },
  removeCbk: () => void
};

const errorOption = (err: ErrorInfo, labels: LabelEditingState[], errorLabelId: number, weight: number): SelectOption<number> => {
  let isDisabled = false;
  let reason = "";
  if (err.data.has_error_label && (!labels.some((ls) => ls.label === errorLabelId))) {
    isDisabled = true;
    reason += ' (requires "error" label)';
  }
  if (err.data.min_weight && (weight < err.data.min_weight)) {
    isDisabled = true;
    reason += ` (at least ${err.data.min_weight}g)`;
  }
  const numLabels = labels.filter((l) => l.label > 0).length;
  if (err.data.min_labels && (numLabels < err.data.min_labels)) {
    isDisabled = true;
    reason += ` (at least ${err.data.min_labels} labels)`;
  }
  if (err.data.num_labels && (numLabels !== err.data.num_labels)) {
    isDisabled = true;
    reason += ` (exactly ${err.data.num_labels} labels)`;
  }
  return ({
    value: err.id, label: `${err.error_name} ${reason}`, isDisabled
  });
};

const ErrorEditor = ({labels, error, errors, errorLabelId, currentImageData, reset, setter, removeCbk}: ErrorEditorParams) => {
  const weight = currentImageData ? currentImageData.weight : 0;
  const errorOptions = useMemo(() => {
    if (!errors)
      return [];
    const opts: SelectOption<number>[] = errors.map((err) => errorOption(err, labels, errorLabelId, weight));
    if ((error.error > 0) && opts.find((opt) => opt.value === error.error).isDisabled) // if the error got disabled, remove self :D
      removeCbk();
    return opts;
  }, [labels, errors, errorLabelId, error.error, removeCbk, weight]);
  return <div className="ee-container">
    <div className="ee-left">
      <Select
        options={errorOptions}
        value={error.error >= 0 ? {value: error.error, label: errors.find((e) => e.id === error.error).error_name} : null}
        onChange={(opt) => setter.error(opt.value)}
      />
    </div>
    <div className="ee-right" onClick={removeCbk}>X</div>
  </div>;
};

const labelObj = (label: number, percentage: number, transformed: string | null, editing: boolean): LabelEditingState => ({
  label,
  percentage,
  transformed,
  editing
});

const errorObj = (error: number): ErrorEditingState => ({error});

type ErrorsResponse = {
  error_label_id: number,
  errors: ErrorInfo[]
};

type LabelSelectorHandle = {
  labelComplete: () => boolean,
  selection: () => LabelSelection[],
  errors: () => number[]
};

type LabelSelectorParams = {
  initialLabels?: LabelSelection[],
  initialErrors?: number[],
  currentImageData?: any,
  allLabels: Label[],
  reset: number,
  hasMake100?: boolean,
  labelChangeCbk: () => void,
  errorChangeCbk: () => void
}

const LabelSelector: React.ForwardRefRenderFunction<LabelSelectorHandle, LabelSelectorParams> = ({initialLabels = null, initialErrors = null, currentImageData, allLabels, reset, hasMake100, labelChangeCbk, errorChangeCbk}: LabelSelectorParams, ref) => {
  const [labels, setLabels] = useState<LabelEditingState[]>([]);
  const [errors, setErrors] = useState<ErrorEditingState[]>([]);
  const error_data = useFetch<ErrorsResponse>("/get-errors");
  const labelByID = useCallback(
    (labelId: number): Label => allLabels.find((l) => l.id === labelId), [allLabels]);

  const getTransformed = useCallback((tr: boolean | null, lid: number): string | null => {
    if (tr === true)
      return labelByID(lid).transformed;
    else if (tr === false)
      return labelByID(lid).untransformed;
    return null;
  }, [labelByID]);

  useEffect(() => {
    if (initialLabels && initialLabels.length) {
      const labels_ = initialLabels.map((il) => labelObj(il.id, il.percentage, getTransformed(il.transformed, il.id), false));

      const percTotal = labels_.reduce((a: number, b: LabelEditingState) => a + b.percentage, 0);
      if (percTotal < 100) {
        const i = labels_.findIndex((label) => label.label === 0);
        if (i >= 0) {
          labels_[i].percentage += 100 - percTotal;
          labels_[i].editing = true;
        }
        else
          labels_.push(labelObj(0, 100 - percTotal, null, true));
      }

      setLabels(labels_);
    }
    else
      setLabels([labelObj(0, 100, null, true)]);
  }, [initialLabels, reset, getTransformed]);

  useEffect(() => {
    if (initialErrors && initialErrors.length) {
      const errors_ = initialErrors.map((error) => ({error}));
      if (errors_.every((e) => e.error > 0))
        errors_.push(errorObj(-1));
      setErrors(errors_);
    }
    else
      setErrors([errorObj(-1)]);
  }, [initialErrors, reset]);

  useEffect(() => labelChangeCbk(), [labels, labelChangeCbk]);
  useEffect(() => errorChangeCbk(), [errors, errorChangeCbk]);

  const setLabel = (i: number, l: number) => {
    labels[i].label = l;
    setLabels([...labels]);
  };
  const setPercentage = (i: number, p: string) => {
    labels[i].percentage = parseInt(p);
    setLabels([...labels]);
  };
  const setTransformed = (i: number, t: string) => {
    labels[i].transformed = t;
    setLabels([...labels]);
  };
  const setEditing = (i: number, e: boolean) => {
    labels[i].editing = e;

    if (e) {
      labels.forEach((label, ndx) => { if (ndx !== i) label.editing = false; }); // close all the others
      const empty = labels.findIndex((label) => label.label === 0);
      if (empty !== -1 && empty !== i) labels.splice(empty, 1); // remove the empty label if it exists and it's not the one being focused
    }

    setLabels([...labels]);
  };
  const setError = (i: number, e: number) => {
    if (errors.find((err) => err.error === e)) // don't add the same error multiple times...
      return;
    errors[i].error = e;
    if (errors.every((e) => e.error >= 0))
      errors.push(errorObj(-1));
    setErrors([...errors]);
  };

  const confirmLabel = () => {
    const totalPerc: number = labels.reduce((a, b) => a + b.percentage, 0);

    // check that percentages add up to at most 100
    if (totalPerc > 100) {
      let diff = totalPerc - 100;

      // first, try to remove the overhead from empty labels, if any
      while (true) { // eslint-disable-line no-constant-condition
        const empty = labels.findIndex((label) => label.label === 0);
        if (empty < 0)
          break;
        if (diff >= labels[empty].percentage) { // remove the empty label
          diff -= labels[empty].percentage;
          labels.splice(empty, 1);
          if (diff === 0)
            break;
        }
        else { // decrease its percentage by the diff
          labels[empty].percentage -= diff;
          diff = 0;
          break;
        }
      }

      for (let l: number = labels.length - 1; l >= 0; l--) {
        if (diff === 0)
          break;

        const i_perc = labels[l].percentage;
        if (i_perc > diff) {
          labels[l].percentage = i_perc - diff;
          diff = 0;
          break;
        }
        else {
          labels[l].percentage = 1;
          diff -= i_perc - 1;
        }
      }
      setLabels([...labels]);
    }

    if ((totalPerc < 100) && labels.every((e) => e.label !== 0))
      setLabels(labels.concat([labelObj(0, 100 - totalPerc, null, true)]));
  };

  const removeLabel = (i: number) => {
    let newLabels = [...labels];
    newLabels.splice(i, 1);

    if (newLabels.length > 0) {
      const totalPerc = newLabels.reduce((a, b) => a + b.percentage, 0);

      if ((totalPerc < 100) && newLabels.every((e) => e.label !== 0)) // not 100% and no "unfilled" label
        newLabels = newLabels.concat([labelObj(0, 100 - totalPerc, null, true)]);
      else if (totalPerc < 100) // cover for the difference by increasing % on the "unfilled" label
        newLabels.find((label) => label.label === 0).percentage += 100 - totalPerc;
    }
    else
      newLabels = [labelObj(0, 100, null, true)];

    setLabels(newLabels);
  };
  const removeError = (i: number) => {
    const newErrors = [...errors];
    newErrors.splice(i, 1);
    if ((newErrors.length === 0) || !newErrors.find((err) => err.error < 0))
      newErrors.push(errorObj(-1));
    setErrors(newErrors);
  };

  const make100 = (i: number) => {
    // remove all but i'th label, and make that label 100%
    const newLabels = [labels[i]];
    newLabels[0].percentage = 100;
    setLabels(newLabels);
  };

  const les2ls = useCallback((label: LabelEditingState): LabelSelection => {
    const res = {
      id: label.label,
      percentage: label.percentage,
      transformed: null
    };
    if (label.transformed)
      res.transformed = label.transformed === labelByID(label.label).transformed;
    return res;
  }, [labelByID]);

  useImperativeHandle(ref, () => ({
    labelComplete: (): boolean => {
      const currentTotal = labels.reduce((a: number, b: LabelEditingState) => a + (b.label ? b.percentage : 0), 0);
      // console.log(currentTotal);
      return currentTotal === 100;
    },
    selection: (): LabelSelection[] => labels.map(les2ls),
    errors: (): number[] => errors.filter((err) => err.error > 0).map((err) => err.error)
  }), [labels, errors, les2ls]);

  if (!error_data.loaded)
    return <></>;

  return <div>
    <h1 className="ee-heading">Labels</h1>
    {labels.map((label, i) => <div key={`LabelEditor${i}`}>
      <LabelEditor
        label={label}
        allLabels={allLabels}
        reset={reset}
        setter={{
          label: (l: number) => setLabel(i, l),
          percentage: (p: string) => setPercentage(i, p),
          transformed: (t: string) => setTransformed(i, t),
          editing: (e: boolean) => setEditing(i, e)
        }}
        confirmLabelCbk={() => confirmLabel()}
        removeCbk={() => removeLabel(i)}
        labelByID={labelByID}
      />
      {hasMake100 && (labels.length > 1) ? <button className="lli-make-100" onClick={() => make100(i)}>Make 100%</button> : null}
    </div>)}
    <h1 className="ee-heading">Errors</h1>
    {errors.map((error, i) => <ErrorEditor
      key={i}
      labels={labels}
      error={error}
      errors={error_data.data.errors}
      errorLabelId={error_data.data.error_label_id}
      currentImageData={currentImageData}
      reset={reset}
      setter={{error: (e: number) => setError(i, e)}}
      removeCbk={() => removeError(i)}
    />)}
  </div>;
};

export default forwardRef(LabelSelector);
