import React from "react";
import ReactDOM from "react-dom";

import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/ie11"; // For IE 11 support
import "./polyfill";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";


import { Amplify } from "aws-amplify";
import config from "./config";
import "./global.js";

Amplify.configure({
  Auth: {
    Cognito: {
      // mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      // identityPoolId: config.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID,
      userPoolClientId: config.cognito.APP_CLIENT_ID
    }
  },
  // API: {
  //   endpoints: [
  //     {
  //       name: 'testApiCall',
  //       endpoint: config.apiGateway.URL,
  //       region: config.apiGateway.REGION
  //     }
  //   ]
  // }
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
