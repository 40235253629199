import React from "react";
import { DVStatus, AgentsDV, LabelList, ImagesDV, Task } from "./_types";
import { cloneTask } from "../../utils/helpers";
import TaskCard from "./TaskCard";
import { printLabel } from "../../containers/LabelSelector/LabelSelector";

const SubtypeGroup: React.FC<{
  subtype: string | number,
  tasks: Task[],
  labelmap: LabelList | null,
  state: (ImagesDV | AgentsDV),
  setTaskStatus: (status: DVStatus, task: Task) => void,
  loadMoreImages: (subtype: string | number, previousId: string) => void,
  handleClickOnImage: (task: Task | null) => void,
  editedTasks: number[],
  imageURLs: { [imageId: string]: string }
}> = (props) => {
  const handleSeeMore = () => {
    const after = props.tasks.length ? props.tasks.map((task) => task.sort_key).sort().pop() : undefined;
    props.loadMoreImages(props.subtype, after);
  };

  const generateLabelString = (): JSX.Element => {
    let labelString: string | number;
    if (props.labelmap && typeof (props.subtype) === "number")
      labelString = props.labelmap[props.subtype] ? printLabel(props.labelmap[props.subtype], 0, " > ") : "[missing label]";

    else
      labelString = props.subtype;


    if (props.state.mode === "check images") {
      const approvedTask = labelString in props.state.label_pending ? props.state.label_pending[labelString].approved : 0;
      const requiredTask = labelString in props.state.label_pending ? props.state.label_pending[labelString].required : 0;
      return <span>{labelString}<p className={approvedTask >= requiredTask ? "green" : "red"}>({approvedTask}/{requiredTask})</p></span>;
    }
    return <span>{labelString}</span>;
  };

  return (
    <div className="subtypeGroup">
      {props.subtype !== "outliers" && props.subtype !== "accuracy assessment" &&
        <h2>{generateLabelString()}</h2>
      }
      <div className="taskList">
        {props.tasks.map(
          (task) => (
            <TaskCard
              key={task.id}
              state={props.state}
              labelmap={props.labelmap}
              task={task}
              setStatus={(status: DVStatus) => props.setTaskStatus(status, task)}
              urls={{ currURL: props.imageURLs[task.image_id], prevURL: props.imageURLs[task.prev_image_id] }}
              handleClickOnImage={() => {
                props.handleClickOnImage(cloneTask(task));
              }}
              edited={props.editedTasks.includes(task.id)}
              selectionDisabled={
                props.state &&
                (
                  props.state.mode === "assess accuracy" ||
                  props.state.dv_type === "outliers" ||
                  (task.labels.length > 1)
                ) &&
                props.editedTasks.includes(task.id)
              }
            />
          )

        ) || "Loading ...."}</div>
      {props.state.mode !== "review images" &&
        props.subtype !== "outliers" &&
        !(props.state.mode === "assess accuracy" && props.state.agent_id) && // single agent's AA
        <div className="seeMoreButton" onClick={handleSeeMore}>See more &#709;</div>}
    </div>
  );
};

export default SubtypeGroup;
