import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Prompt } from "react-router-dom";
import { DVStatus, AgentsDV, LabelList, ImagesDV, Task, DVOutletPageState, Props } from "./_types";
import { LabelSelection } from "../../containers/LabelSelector";
import { getImageURL, openDVOutletPage, handleTaskResponse, cloneTask } from "../../utils/helpers";
import { getFetch } from "../../utils/fetchUtils";
import { Button } from "@material-ui/core";
import LabellingDataValidity from "./LabellingDataValidity";
import useFetch from "../../utils/useFetch";
import SubtypeGroup from "./SubtypeGroup";

// Helper functions: do something based on the current mode
const switchModes = (
  state: Props["location"]["state"],
  allAgents: (state: AgentsDV) => any,
  singleAgent: (state: AgentsDV) => any,
  imagesCheking: (state: ImagesDV & DVOutletPageState) => any,
  imagesReview: (state: ImagesDV & DVOutletPageState) => any,
  alternative: () => any,
): any => {
  if (!state) return alternative(); // Edge case: page was openend by URL

  if (state.mode === "assess accuracy") {
    if (state.agent_id) return singleAgent(state); // Page should do single agent accuracy assessment
    return allAgents(state); // Page should do all agents accuracy assessment
  }

  if (state.mode === "check images") return imagesCheking(state); // Page should do image checking
  if (state.mode === "review images") return imagesReview(state); // Page should do image review
};

// Helper functions: close page
const closePage = (state_: Props["location"]["state"], history: Props["history"]) => switchModes(
  state_,
  () => history.push({ pathname: "/dataValidity" }),
  () => history.push({ pathname: "/dataValidity" }),
  (state) => openDVOutletPage(
    {
      outlet_id: state.outlet_id,
      outlet_name: state.outlet_name,
      property_name: state.property_name,
      dv_date: state.dv_date,
      oldest_unlabelled: state.oldest_unlabelled,
      measurement_start: state.measurement_start,
    },
    history
  ),
  (state) => openDVOutletPage(
    {
      outlet_id: state.outlet_id,
      outlet_name: state.outlet_name,
      property_name: state.property_name,
      dv_date: state.dv_date,
      oldest_unlabelled: state.oldest_unlabelled,
      measurement_start: state.measurement_start,
    },
    history
  ),
  () => history.push({ pathname: "/dataValidity" }),
);

// Helper functions: get tasks URL
const getTasksUrl = (location: Props["location"], after: string = undefined, subtype: string | number = undefined): string => switchModes(
  location.state,
  () => `/get-dv-images-agents${after ? `?after=${after}` : ""}`,
  (state) => `/get-dv-images-agents?agent_id=${state.agent_id}`,
  (state) => `/get-dv-images-outlets?outlet_id=${state.outlet_id}&type=${state.dv_type}&mode=${state.mode}&period=${state.period}${after ? `&after=${after}` : ""}${subtype ? "&subtype=" + subtype : ""}`,
  (state) => `/get-dv-images-outlets?outlet_id=${state.outlet_id}&type=${state.dv_type}&mode=${state.mode}&period=${state.period}`,
  () => "",
);


// Components: Main Page
const ImagesDataValidity: React.FC<Props> = ({ location, history }) => {
  // State: data fetching
  const [tasks, setTasks] = useState<Task[]>(null);

  const labelmap = useFetch<LabelList>(
    "/get-labels?active=true",
    { extract: (labelData: { allLabels: LabelList }) => labelData.allLabels }
  );

  // State: page logic
  const [shouldSave, setShouldSave] = useState<boolean>(false);
  const [editTask, setEditTask] = useState<Task | null>(null);
  const [savedScrollPosition, setSavedScrollPosition] = useState<number | null>(null);
  const [editedIDs, setEditedIDs] = useState<number[]>([]);
  const [imageURLs, setImageURLS] = useState<{ [imageId: string]: string }>({});
  const [loadingTrashevents, setLoadingTrashevents] = useState<boolean>(false);


  const tasksFetchData = useFetch<Task[]>(
    getTasksUrl(location),
    { extract: (data: Task[]) => handleTaskResponse(data, setTasks, location)}
  );
  if (tasksFetchData.error) {
    alert(`Unexpected error ${tasksFetchData.error}`);
    closePage(location.state, history);
  }

  // Update image URLS
  useEffect(() => {
    if (tasks) {
      const newURLs = { ...imageURLs };
      let changed = false;
      for (const task of tasks) {
        if (!(task.image_id in imageURLs)) {
          newURLs[task.image_id] = getImageURL(task.image_id);
          changed = true;
        }

        if (!(task.prev_image_id in imageURLs)) {
          newURLs[task.prev_image_id] = getImageURL(task.prev_image_id);
          changed = true;
        }
      }

      if (changed) setImageURLS(newURLs);
    }
  }, [tasks, imageURLs]);

  // Remove the "overflow: hidden" from <body> and put it back on unmount
  useEffect(() => {
    document.getElementsByTagName("body")[0].style.overflowY = "auto";
    return () => { document.getElementsByTagName("body")[0].style.overflowY = "hidden"; };
  }, []);

  // Quit page if directly opened by URL
  useEffect(() => {
    if (!location.state)
      closePage(location.state, history);
  }, [location, history]);

  // Quit if no tasks were found for this dv_type and mode
  useEffect(() => {
    if (tasks && tasks.length === 0) {
      closePage(location.state, history);
      alert("No images found! Going back to DV...");
    }
  }, [tasks, history, location]);

  // Submit data to backend and quit when requested
  useEffect(() => {
    if (shouldSave && location?.state && tasks) {
      const controller = new AbortController();
      const data = {
        "mode": location.state.mode,
        "tasks": tasks.map((_task) => ({
          "id": _task.id,
          "trashevent_id": _task.trashevent_id,
          "type_id": _task.type_id,
          "status": _task.status,
          "edited": editedIDs.includes(_task.id),
          "labels": _task.labels,
          "errors": _task.errors,
        }))
      };

      getFetch("/save-dv-images", {
        method: "POST", signal: controller.signal, data: data
      })
        .then(() => closePage(location.state, history))
        .catch((e: Error) => {
          if (e.name !== "AbortError") {
            alert(`Unexpected error ${e}`);
            closePage(location.state, history);
          }
        });

      return () => controller.abort();
    }
  }, [shouldSave, location, history, tasks, editedIDs]);

  // Scroll when needed
  useLayoutEffect(() => {
    if (!editTask && savedScrollPosition) {
      window.scrollTo({ top: savedScrollPosition });
      setSavedScrollPosition(null);
    }
  }, [editTask, savedScrollPosition, setSavedScrollPosition]);

  // Main prompt before leaving
  useEffect(() => {
    const keepOnPage = (e) => {
      if (tasks && tasks.length !== 0 && !shouldSave)
        e.returnValue = "";
    };
    window.addEventListener("beforeunload", keepOnPage);

    return () => window.removeEventListener("beforeunload", keepOnPage);
  }, [tasks, shouldSave]);


  // Helper functions
  const capitalize = (word: string): string => word.charAt(0).toUpperCase() + word.slice(1);
  const getTitle = (): string => switchModes(
    location.state,
    () => "Accuracy assessment: All agents",
    (state) => `Accuracy assessment: ${state.agent_name}`,
    (state) => `${capitalize(state.property_name)} - ${capitalize(state.outlet_name)}: ${capitalize(state.dv_type.replace("_", " "))}`,
    (state) => `${capitalize(state.property_name)} - ${capitalize(state.outlet_name)}: ${capitalize(state.dv_type.replace("_", " "))}`,
    () => "",
  );

  const setTaskStatus = (status: DVStatus, task: Task): void => {
    setTasks((tasks_: Task[]) => {
      const tasksCopy: Task[] = tasks_.map(cloneTask);
      tasksCopy.find((task_) => task_.id === task.id).status = status;
      return tasksCopy;
    });
  };
  const checkArraysMatch = (a: any[], b: any[]): boolean => JSON.stringify(a) === JSON.stringify(b);
  const sortLabels = (a: LabelSelection[]): LabelSelection[] => a.sort((l1, l2) => l1.id - l2.id);
  const sortErrors = (a: number[]): number[] => a.sort((e1, e2) => e1 - e2);
  const setTaskLabelsErrors = (labels: LabelSelection[], errors: number[], task: Task): void => {
    const labelsChanged: boolean = !checkArraysMatch(sortLabels(labels), sortLabels(task.original_labels));
    const errorsChanged: boolean = !checkArraysMatch(sortErrors(errors), sortErrors(task.original_errors));

    setTasks((tasks_: Task[]) => {
      const tasksCopy: Task[] = tasks_.map(cloneTask);

      tasksCopy.find((task_) => task_.id === task.id).labels = labels.map((label) => ({ ...label }));
      tasksCopy.find((task_) => task_.id === task.id).errors = [...errors];
      return tasksCopy;
    });

    const status: DVStatus = (labelsChanged || errorsChanged) ? "rejected" : "approved";
    switchModes(
      location.state,
      () => () => setTaskStatus(status, task),
      () => () => setTaskStatus(status, task),
      (state) => () => {
        if (state.dv_type === "outliers")
          setTaskStatus("approved", task);

        else if (labels.length > 1)
          setTaskStatus("rejected", task);
      },
      (state) => () => {
        if (state.dv_type === "outliers")
          setTaskStatus("approved", task);

        else if (labels.length > 1)
          setTaskStatus("rejected", task);
      },
      () => () => null
    )();

    if (labelsChanged || errorsChanged) {
      if (!editedIDs.includes(editTask.id))
        setEditedIDs((ids) => [...ids, editTask.id]);
    }
    else if (editedIDs.includes(editTask.id))
      setEditedIDs((ids) => ids.filter((id) => id !== editTask.id));
  };

  // Fetch more images of a given subtype
  const loadMore = useCallback((subtype: string | number, after: string): void => {
    const url: string = getTasksUrl(location, after, subtype);
    setLoadingTrashevents(true);
    getFetch(url, {})
      .then((data: Task[]) => {
        handleTaskResponse(data, setTasks, location);
        setLoadingTrashevents(false);
      })
      .catch((error) => {
        alert(`Unexpected error ${tasksFetchData.error}`);
        closePage(location.state, history);
      });
  }, [location, history, tasksFetchData]);

  // Generators: subtype groups
  const tasksPerSubtype = useMemo(() => {
    const finalJSX: JSX.Element[] = [];
    if (tasks) {
      const subtypes_arr: (string | number)[] = tasks.map((task) => task.subtype);
      const subtypes: (string | number)[] = Array.from(new Set(subtypes_arr));
      for (const subtype of subtypes) {
        const subtype_tasks = tasks.filter((task) => task.subtype === subtype);
        const subtype_tasks_ids = subtype_tasks.map((task) => task.id);
        finalJSX.push(
          <SubtypeGroup
            key={subtype}
            subtype={subtype}
            tasks={subtype_tasks}
            labelmap={labelmap.data}
            state={location.state}
            loadMoreImages={loadMore}
            handleClickOnImage={(task) => {
              setEditTask(task);
              setSavedScrollPosition(window.pageYOffset);
            }}
            setTaskStatus={setTaskStatus}
            editedTasks={editedIDs.filter((id) => subtype_tasks_ids.includes(id))}
            imageURLs={imageURLs}
          />
        );
      }
    }
    return finalJSX;
  }, [tasks, editedIDs, labelmap, imageURLs, location, loadMore]);

  // Render
  return (
    <div className="imagesDataValidity">
      <h1>{location.state && getTitle()}</h1>
      <Prompt
        when={tasks && tasks.length !== 0 && !shouldSave}
        message="Leave site? Changes you made may not be saved."
      />
      {
        editTask
          ? <LabellingDataValidity
            taskData={editTask}
            labelData={Object.values(labelmap.data)}
            imageURL={imageURLs[editTask.image_id]}
            prevImageURL={imageURLs[editTask.prev_image_id]}
            requestClose={() => setEditTask(null)}
            saveImageChanges={(labels: LabelSelection[], errors: number[]) => {
              setTaskLabelsErrors(labels, errors, editTask);
              setEditTask(null);
            }}
            cancelChanges={() => setEditTask(null)}
            title={new Date(editTask.datetime).toString().split("GMT")[0]}
          />
          : <div>{tasksPerSubtype}</div>
      }
      <div className="buttons">
        <Button variant="outlined" color="primary" disabled={loadingTrashevents} onClick={() => setShouldSave(true)}>
          {
            switchModes(
              location.state,
              () => "Save & back to DV",
              () => "Save & back to DV",
              (state) => `Save & back to ${state.outlet_name} DV`,
              (state) => `Save & back to ${state.outlet_name} DV`,
              () => "Save & back to DV"
            )
          }
        </Button>
      </div>
    </div>
  );
};

export default ImagesDataValidity;
