import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { Button, Snackbar } from "@material-ui/core";
import Select from "react-select";
import moment from "moment";
import useFetch from "../../utils/useFetch";
import { openDVImagesPage } from "../../utils/helpers";
import { ImagesDV, DVOutletPageState } from "./_types";
import { getFetch } from "../../utils/fetchUtils";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import RefreshIcon from "@material-ui/icons/Refresh";
import ReactTooltip from "react-tooltip";

type OutletDVStatsResponseSub = {
  checked: number,
  validated: number,
  pending: number,
  latest_approved: string | null
};

type OutletDVStatsResponse = {
  overview: OutletDVStatsResponseSub,
  categories: OutletDVStatsResponseSub,
  tracked_items: OutletDVStatsResponseSub,
  outliers: {
    approved: number,
    pending: number,
    oldest_pending: string | null,
    devices_weight_threshold: Record<string, number>
  }
};

const OutletDataValidity = (props: {location: {state: DVOutletPageState}, history: any}) => {
  const [selectedPeriod, setSelectedPeriod] = useState({ value: "", label: "" });
  const [periods, setPeriods] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [reloadCards, setReloadCards] = useState(0);
  const [disableUpdate, setDisableUpdate] = useState(false);

  // we need to remove the "overflow: hidden" from <body> and put it back on unmount
  useEffect(() => {
    document.getElementsByTagName("body")[0].style.overflowY = "auto";
    return () => { document.getElementsByTagName("body")[0].style.overflowY = "hidden"; };
  });

  const getSelectablePeriods = useCallback(() => {
    const sixMoAgo = moment().subtract(6, "months");
    const measurementStart = moment(props.location.state.measurement_start, "DD.MM.YYYY").startOf("month");
    const lastPeriod = sixMoAgo > measurementStart ? sixMoAgo : measurementStart;
    const dvDate = moment(props.location.state.dv_date, "DD.MM.YYYY").startOf("month");

    let availablePeriods = [];
    const currentMonth = dvDate.clone();

    /* eslint-disable no-unmodified-loop-condition */
    while (currentMonth >= lastPeriod) {
      availablePeriods = [...availablePeriods, { value: currentMonth.format("YYYY/MM"), label: currentMonth.format("MMMM YYYY") }];
      currentMonth.subtract(1, "months");
    }
    setPeriods(availablePeriods);
    setSelectedPeriod({ value: dvDate.format("YYYY/MM"), label: dvDate.format("MMMM YYYY") });
  }, [props.location.state.dv_date, props.location.state.measurement_start]);

  useEffect(() => {
    if (props.location.state)
      getSelectablePeriods();
    else
      props.history.push("/dataValidity");
  }, [getSelectablePeriods, props.history, props.location.state]);

  const outlet_id = props.location.state ? props.location.state.outlet_id : -1;
  const fetchedData = useFetch<OutletDVStatsResponse>(`/get-outlet-dv-stats?outlet_id=${outlet_id}&period=${selectedPeriod.value}&reload=${reloadCards}`);

  if (!props.location.state) {
    return <div>
      <p>Error</p>
      <p><Link to="/dataValidity">Return to the Data Validity page</Link></p>
    </div>;
  }

  const updateDv = () => {
    if (outlet_id) {
      setDisableUpdate(true);
      setTimeout(() => setDisableUpdate(false), 1000);
      getFetch(`/update-dv-date?outlet_id=${outlet_id}`, { api: global.non_vpc_url, data: null })
        .then(() => {
          setShowMessage(true);
        })
        .catch((e) => {
          alert(`Something went wrong... Couldn't update DV date: ${e}`);
        });
    }
    else
      alert("Something went wrong... Couldn't update DV date: outlet_id missing");

  };

  const refreshCards = () => {
    setReloadCards(reloadCards + 1);
  };

  const dv_images_payload: DVOutletPageState & { period: string, history: any } = {
    outlet_id: props.location.state.outlet_id,
    outlet_name: props.location.state.outlet_name,
    property_name: props.location.state.property_name,
    dv_date: props.location.state.dv_date,
    oldest_unlabelled: props.location.state.oldest_unlabelled,
    measurement_start: props.location.state.measurement_start,
    period: selectedPeriod.value,
    history: props.history
  };

  let errorMsg = "Loading...";
  if (fetchedData.error) {
    errorMsg = fetchedData.error.includes("404")
      ? "No data available for the given month."
      : "Something went wrong. Error: " + fetchedData.error;
  }

  return (
    <div className="animated fadeIn">
      <div>
        {showMessage
          ? <Snackbar
            message="Updating DV date... It might take 1-2 mins."
            open={showMessage}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            autoHideDuration={4000}
            onClose={() => setShowMessage(false) }
          /> : ""}

        <h1>{props.location.state.property_name} - {props.location.state.outlet_name}</h1>
        <div className="filterContainer">
          <div className="col-auto">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="period">
                  Period
                </span>
              </div>
              <Select
                className="periodSelect"
                aria-describedby="period"
                name="selectPeriod"
                key="selectPeriod"
                options={periods}
                value={selectedPeriod}
                onChange={setSelectedPeriod}
              />
            </div>
          </div>
          <div className="col-auto">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" >
                  Data Validity Date
                </span>
              </div>
              <span className="nonChangeableValue">{props.location.state && props.location.state.dv_date}</span>
            </div>
          </div>
          <div className="col-auto">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  Oldest unlabelled date
                </span>
              </div>
              <span className="nonChangeableValue">{props.location.state && props.location.state.oldest_unlabelled}</span>
            </div>
          </div>
          <Button disabled={disableUpdate} onClick={updateDv} variant="contained" size="medium">Update DV date</Button>
          <Button onClick={refreshCards} variant="contained"> <RefreshIcon/> </Button>
        </div>

        {fetchedData.loaded
          ? <div className="cardContainer">
            <StatCard key="overview" type="overview" title="Top Wasted Items" outlet_id={outlet_id} dv_images_payload={dv_images_payload} {...fetchedData.data.overview} />
            <StatCard key="categories" type="categories" title="Categories" outlet_id={outlet_id} dv_images_payload={dv_images_payload} {...fetchedData.data.categories} />
            <StatCard key="tracked" type="tracked_items" title="Tracked Items" outlet_id={outlet_id} dv_images_payload={dv_images_payload} {...fetchedData.data.tracked_items} />
            <StatCard key="outliers" type="outliers" title="Outliers" outlet_id={outlet_id} dv_images_payload={dv_images_payload} {...fetchedData.data.outliers} />
          </div>
          : <p><br />{errorMsg}</p>
        }
      </div>
    </div>
  );
};

const PendingDVTooltip = (props) => <ReactTooltip class="pendingDVTooltip" id={props.type} event="click" globalEventOff="click" place="right" type="info" arrowColor="transparent" clickable>
  {
    Object.keys(props.pendingLabel).map((label) => {
      if (props.pendingLabel[label].pending > 0) {
        const labelSplit = label.split(">");
        const labelWithoutDescription = labelSplit.length > 1 ? labelSplit.slice(0, -1).reduce((prev, label) => prev + ">" + label) + ">" : "";

        return <div className="text-big" key={label}>
          {labelWithoutDescription} <b>{labelSplit[labelSplit.length - 1]} : {props.pendingLabel[label].pending} </b>
        </div>;
      }
      return <></>;
    })
  }
</ReactTooltip>;

type StatCardParams = {
  title: string,
  type: ImagesDV["dv_type"],
  outlet_id: number,
  checked?: number,
  approved?: number,
  validated?: number,
  pending?: number,
  latest_approved?: string | null,
  oldest_pending?: string | null,
  devices_weight_threshold?: Record<string, number>,
  dv_images_payload: DVOutletPageState & { period: string, history: any },
  label_pending?: ImagesDV["label_pending"]
};

const StatCard = (props: StatCardParams) => {
  const formatDate = (date: string) => {
    if (!date) return "";
    date = date.split(" ")[0];
    return date.split("/").reverse().join(".");
  };

  const checkMoreImages = () => openDVImagesPage(
    {
      ...props.dv_images_payload,
      mode: "check images",
      dv_type: props.type,
      label_pending: {...props.label_pending}
    },
    props.dv_images_payload.history,
  );


  const reviewCheckedImages = () => openDVImagesPage(
    {
      ...props.dv_images_payload,
      mode: "review images",
      dv_type: props.type,
      label_pending: {...props.label_pending}
    },
    props.dv_images_payload.history
  );

  const labelPending = (labels: ImagesDV["label_pending"]) => Object.keys(labels).filter((label) => labels[label].pending > 0);

  return (
    <div className="statCard">
      <div className={props.type === "outliers" ? "statCardTitle statCardOutliersTitle" : "statCardTitle"}><h2>{props.title}</h2></div>
      {props.type === "outliers"
        ? <div className="statCardInfo">
          <div key="accepted"># Approved: <b>{props.approved}</b></div>
          <div key="pending"># Pending: <b>{props.pending}</b></div>
          <div key="oldest">Oldest pending: <b>{formatDate(props.oldest_pending)}</b></div>
          <div key="thresholds">
            <table>
              <thead>
                <tr >
                  <th>Device</th>
                  <th>Outliers weight threshold (g)</th>
                </tr>
              </thead>
              <tbody>
                {props.devices_weight_threshold
                  ? Object.keys(props.devices_weight_threshold).map((device) => <tr key={device}>
                    <td key="device">{device}</td>
                    <td key="threshold">{props.devices_weight_threshold[device]}</td>
                  </tr>
                  )
                  : <tr><td>NA</td></tr>
                }
              </tbody>
            </table>
          </div>
          <Button key="check" variant="contained" size="small" onClick={checkMoreImages}>Check outliers</Button><br />
          <br />
          <Button key="review" variant="contained" size="small" onClick={reviewCheckedImages}>Review checked outliers</Button>
        </div>
        : <div className="statCardInfo">
          <div key="checked"># Checked: <b>{props.checked}</b></div>
          <div key="validated"># Validated: <b>{props.validated}</b></div>
          <div className="statCardPending" key="pending">
              # Pending
            {Object.keys(labelPending(props.label_pending)).length > 0
              ? <sup>
                <InfoOutlinedIcon data-tip data-for={props.type} style={{ fontSize: 16, marginLeft: "5px"}} />
                <PendingDVTooltip type={props.type} pendingLabel={props.label_pending}/>
              </sup> : <div></div>}
              : &nbsp;
            <b>{props.pending}</b>
          </div>
          <div key="latest">Latest validated: <b>{formatDate(props.latest_approved)}</b></div>
          <Button key="check_more" variant="contained" size="small" onClick={checkMoreImages}>Check more images</Button><br />
          <br />
          <Button key="review" variant="contained" size="small" onClick={reviewCheckedImages}>Review checked images</Button>
        </div>
      }
    </div>
  );
};

export default OutletDataValidity;
