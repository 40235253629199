import React, {useState, useEffect, useCallback, useContext} from "react";
import $ from "jquery";
import { getDateTime } from "../../utils/dateUtils.js";

import { getFetch } from "../../utils/fetchUtils";
import useFetch from "../../utils/useFetch";
import { UserContext, UserType} from "../../context/userContext";
import LiveCountComponent from "../LiveCountComponent/LiveCountComponent";
import { animateSpinner } from "../../utils/helpers";

import type { Label } from "../../containers/LabelSelector";

type labelDataType = Label & {value: number};

type BatchData = {
  batch_id: number,
  device_id: number,
  count: number,
  cuisine_name: string,
  area_name: string,
  outlet_id: number,
  outlet_name: string,
  property_type: string,
  deployment_id: number
  age_days: number,
  consensus_threshold: number,
  events: {}
};

type Quizzes = {
  active: boolean,
  id: number,
  passing_mark: number | null,
  tolerance: number | null,
  u_score: number,
  data: {
    description: string,
    name: string,
    passing_mark: number,
    tolerance: number
  }
};

type TaskCountsResponse = {
  consensus_checking: number
};

const StartConsensusCheckingBox = ({getKitronCheckFunction}) => {
  const consCount = useFetch<TaskCountsResponse>("/get-task-counts");
  return <StartBatchBox
    type='Labelling'
    titleText={"Decide on consensus" + (consCount.loaded ? ` (${consCount.data.consensus_checking})` : "")}
    boxText='Here you are checking what other agents have labelled and you have to decide who is right, remember that all can be wrong.'
    buttonText='Start Batch'
    getKitronCheck={getKitronCheckFunction}
    buttonPurpose='consensus'
    key='consensus'
  />;
};

const StartBatchBox: React.FC<{
  type: string,
  titleText: string,
  boxText: string,
  buttonText: string,
  getKitronCheck: (queryType: String) => void,
  buttonPurpose: string
}> = (props) => (
  <div className='startBatchBox batchOverviewBox'>
    <h2 className={"boxHeader"}>{props.titleText}</h2>
    <div
      style={{
        "fontSize": "0.9em", "display": "inline-block", "width": "65%"
      }}>{props.boxText}</div>
    <button
      type="button"
      className={"btn btn-primary overviewButton buttonColor" + props.type}
      onClick={() => props.getKitronCheck(props.buttonPurpose)}>{props.buttonText}
    </button>
  </div>
);

const FeedbackBox: React.FC<{ userData: UserType, buttonFunction: () => void }> = (props) => (
  <div>
    <div className={"batchOverviewBox feedbackBox"}>
      <h2 className='boxHeader'>Review your feedback</h2>
      <p>You're doing a great job but there is always room for improvement. Check out the tasks that you have not done correctly in the last 30 days.</p>
      <button
        type="button"
        className={"btn btn-primary overviewButton buttonColor" + props.userData.currentRole}
        style={{"marginTop": "-15px"}}
        onClick={() => props.buttonFunction()}
        disabled={props.userData.currentRole !== "Labelling"}
      >
        {props.userData.currentRole === "Labelling" ? "Check it out!" : "Coming soon!"}
      </button>
    </div>
  </div>
);

const UserOverviewBox: React.FC<{}> = () => {
  const { user } = useContext(UserContext);
  const username = user.username;
  const userData = user;
  let level = 0;

  if (userData.currentRole === "Labelling")
    level = userData.labellingInfo.level;

  else if (userData.currentRole === "Segmentation")
    level = userData.segmentationInfo.level;


  return (
    <div className={"batchOverviewBox userOverviewBox"}>
      <p className={"flexItemRow"}>User: <b>{username}</b></p>
      <p className={"flexItemRow"}>Level: <b>{level}</b></p>
      {userData && userData.isKitron ? <p className={"flexItemRow"}><b>Kitron</b></p> : null}
      <p className={"flexItemRow"}>Avg. daily: <b>{userData.dailyAvg ? userData.dailyAvg : "-"}</b></p>
    </div>
  );
};

const StartBatchPanel: React.FC<{pageTitle: string, userData: UserType, getKitronCheckFunction: (queryType: String) => void, lcc, show: boolean }> = (props) => (
  <div style={{visibility: props.show ? "visible" : "hidden"}}>
    <h2 style={{"fontWeight": 250, "marginBottom": "51px"}}>
      {props.pageTitle}
      {props.lcc}
    </h2>
    {generateStartBatchBoxes(props.userData, props.getKitronCheckFunction)}
  </div>
);

const AvailableBatchesBox: React.FC<{
  batchData: {
    data: Array<BatchData>,
    error: string,
    loaded: boolean
  },
  openBatches: number,
  totalBatches: number,
  startBatchFunction: (batchData: BatchData) => void
}> = (props) => {
  if (props.batchData.error)
    return <p>Error loading batches: {props.batchData.error}</p>;
  if (!props.batchData.loaded)
    return <p>Loading available batches...</p>;

  return (
    <div className={"batchOverviewBox availableBatchesBox"}>
      <h2 className={"boxHeader"}>Current Available Batches</h2>
      <div className={"batchListHeader"}>
        <p className={"flexItemRow"}>Device ID</p>
        <p className={"flexItemRow"}>Location</p>
        <p className={"flexItemRow"}>Cuisine Type</p>
        <p className={"flexItemRow"}>Open batches: <b>{props.openBatches}</b>/{props.totalBatches}</p>
      </div>
      <div className={"batchListBody"}>
        {generateBatchList(props.batchData.data, props.startBatchFunction)}
      </div>
    </div>
  );
};

const generateBatchList = (batchData: Array<BatchData>, startBatchFunction: (BatchData) => void): JSX.Element[] => {
  const batchList = [];

  for (const batch in batchData) {
    const currentBatch = batchData[batch];
    batchList.push(
      <div className={"batchListItem"} key={currentBatch.batch_id}>
        <p className={"flexItemRow paddingTop3"}><b>{currentBatch.device_id}</b></p>
        <p className={"flexItemRow paddingTop3"}>{currentBatch.area_name}</p>
        <p className={"flexItemRow paddingTop3"}>{currentBatch.cuisine_name}</p>
        <button
          type="button"
          className={"btn btn-primary listBatchButton flexItemRow"}
          onClick={() => startBatchFunction(currentBatch)}
        >
          Start batch <p style={{"fontSize": "0.75em"}}>{"(" + currentBatch.count + ")"}</p>
        </button>
      </div>
    );

  }

  return batchList;
};

const AvailableQuizzesBox: React.FC<{quizzes: Array<Quizzes>, startQuiz: (i: number) => void}> = (props) => (
  <div className="batchOverviewBox availableBatchesBox">
    <h2 className='boxHeader'>Available Quizzes</h2>
    <div className='batchListHeader'>
      <p className='flexItemRowHalf'>Quiz ID</p>
      <p className='flexItemRow'>Quiz Name</p>
      <p className='flexItemRowHalf'>Previous Score</p>
      <p className='flexItemRow'>Start</p>
    </div>
    <div className={"batchListBody"}>
      {props.quizzes.length > 0 ? props.quizzes.map((quiz, i) => quizListItem(quiz, () => props.startQuiz(i))) : "You have finished all quizzes."}
    </div>
  </div>
);

const quizListItem = (quiz: Quizzes, handleStartQuiz: () => void) => (
  <div className='batchListItem' key={`quiz_row_${quiz.id}`}>
    <p className='flexItemRowHalf paddingTop3'><b>{quiz.id}</b></p>
    <p className='flexItemRow paddingTop3'>{quiz.data.name}</p>
    <p className='flexItemRowHalf paddingTop3'>{quiz.u_score ? `${quiz.u_score} / ${quiz.passing_mark}` : ""}</p>
    <button type="button" className="btn btn-primary listBatchButton flexItemRow" onClick={handleStartQuiz}>Start quiz</button>
  </div>
);

const generateStartBatchBoxes = (userData: UserType, getKitronCheckFunction: (queryType: String) => void): JSX.Element[] => {
  const startBatchBoxes = [];

  const decideOnConsensusLabellingBox = () => <StartConsensusCheckingBox getKitronCheckFunction={getKitronCheckFunction} />;

  const startRandomBatchLabellingBox = () => <StartBatchBox
    type='Labelling'
    titleText='Start a random batch'
    boxText='Start doing tasks in the next available batch.'
    buttonText='Start Batch'
    getKitronCheck={getKitronCheckFunction}
    buttonPurpose='random'
    key='random'
  />;

  const checkOtherAgentsSegmentationBox = () => <StartBatchBox
    type='Segmentation'
    titleText='Check other agents labels'
    boxText='Check what another agent has labelled and decide if they did it correctly. All or some of it can be right or all of it can be wrong.'
    buttonText='Start Batch'
    getKitronCheck={getKitronCheckFunction}
    buttonPurpose='segmentation_checking'
    key='segmentation_checking'
  />;

  const startRandomBatchSegmentationBox = () => <StartBatchBox
    type='Segmentation'
    titleText='Start a random batch'
    boxText='Start doing tasks in the next available batch.'
    buttonText='Start Batch'
    getKitronCheck={getKitronCheckFunction}
    buttonPurpose='segmentation'
    key='segmentation'
  />;

  const startTrainingLabelling = () => <StartBatchBox
    type='Labelling'
    titleText='Go to training guides'
    boxText='Check out the training guides and tip & tricks.'
    buttonText='Go to manuals'
    getKitronCheck={getKitronCheckFunction}
    buttonPurpose='training'
    key='training'
  />;

  const startTrainingSegmentation = () => <StartBatchBox
    type='Segmentation'
    titleText='Go to training guides'
    boxText='Check out the training guides and tip & tricks.'
    buttonText='Go to manuals'
    getKitronCheck={getKitronCheckFunction}
    buttonPurpose='training'
    key='training'
  />;

  const takeQuiz = () => <StartBatchBox
    type='Labelling'
    titleText='Take quiz'
    boxText='You need to complete all quizzes before testing your accuracy and start labelling.'
    buttonText='Next quiz'
    getKitronCheck={getKitronCheckFunction}
    buttonPurpose='quiz'
    key='quiz'
  />;

  const takeLevel1Exam = () => <StartBatchBox
    type='Labelling'
    titleText='Level 1 exam'
    boxText='You will be tested on a set of 100 pictures. If you get more than 75% of the labels correct, you become a level 1 user and can start labelling new pictures.'
    buttonText='Take exam'
    getKitronCheck={getKitronCheckFunction}
    buttonPurpose='level1exam'
    key='level1exam'
  />;

  const goToTrainingSection = () => <StartBatchBox
    type='Labelling'
    titleText='Go to training section'
    boxText='Check out the training guides, tips & tricks and quizzes.'
    buttonText='Go to training'
    getKitronCheck={getKitronCheckFunction}
    buttonPurpose='training'
    key='training'
  />;

  if (userData.currentRole === "Labelling") {
    switch (userData.labellingInfo.level) {
      case 0:
        if (userData.labellingInfo.has_quizzes)
          startBatchBoxes.push(takeQuiz());
        if (userData.labellingInfo.can_take_exam)
          startBatchBoxes.push(takeLevel1Exam());
        startBatchBoxes.push(goToTrainingSection());
        break;
      case 1:
      case 2:
        startBatchBoxes.push(startRandomBatchLabellingBox());
        startBatchBoxes.push(startTrainingLabelling());
        break;
      case 3:
        startBatchBoxes.push(decideOnConsensusLabellingBox());
        startBatchBoxes.push(startRandomBatchLabellingBox());
        break;
      default:
        break;
    }
  }
  else if (userData.currentRole === "Segmentation") {
    switch (userData.segmentationInfo.level) {
      case 1:
      case 2:
        startBatchBoxes.push(startRandomBatchSegmentationBox());
        startBatchBoxes.push(startTrainingSegmentation());
        break;
      case 3:
        startBatchBoxes.push(checkOtherAgentsSegmentationBox());
        startBatchBoxes.push(startRandomBatchSegmentationBox());
        break;
      default:
        break;
    }
  }

  return startBatchBoxes;
};

const showSpinner = (show: boolean): void => {
  let spinner = $("body").children(".spinner");
  if (show) {
    document.getElementById("overlay").style.display = "block";

    if (spinner.length && !spinner.hasClass("spinner-remove"))
      return null;
    if (!spinner.length)
      spinner = $('<div class="spinner spinner-absolute"/>').appendTo($("body"));
    animateSpinner(spinner, "add");
  }
  else {
    if (spinner.length)
      animateSpinner(spinner, "remove");

    document.getElementById("overlay").style.display = "none";
  }
};

const BatchesOverview: React.FC<{location: { state: undefined | any }, history: any}> = (props) => {
  // Initialize state
  const [labelData, setLabelData] = useState<{[label: number]: labelDataType}>({});
  const [fullLabelData, setFullLabelData] = useState<{[label: number]: labelDataType}>({});
  const nBatchData = useFetch<Array<BatchData>>("/get-available-batches");
  const { user } = useContext(UserContext);
  const getUnfinishedQuizzes_ = useCallback(getUnfinishedQuizzes, []);
  const quizzes = useFetch<Array<Quizzes>>("/training/quiz", {extract: getUnfinishedQuizzes_, do_load: user.labellingInfo.level === 0});
  // null - not loaded yet; false - did not max out, can work; true - maxed out tasks, cannot work
  const [maxedOut, setMaxedOut] = useState<boolean>(null);

  useEffect(() => {
    if (user.username) {
      $("#root").css("background-color", "#fafafa");
      loadLabels();
    }
  }, [user.username]);

  const loadLabels = (): void => {
    getFetch("/get-labels?active=true", {})
      .then((data) => {
        setLabelData(data.allLabels);
        setFullLabelData(data.allLabels);
      });
  };

  const startAvailableBatch = useCallback((batch_info: BatchData): void => {
    showSpinner(true);

    const url = `/get-available-batch-data?batch_id=${batch_info.batch_id}&outlet_id=${batch_info.outlet_id}&deployment_id=${batch_info.deployment_id}`;

    getFetch(url, {method: "GET"})
      .then((data) => {
        if (Object.keys(data).length) {
          try {
            showSpinner(false);
            props.history.push({
              pathname: "/labelling",
              state: {
                batchInfo: batch_info, batchData: data, labelData: labelData, fullLabelData: fullLabelData, checkBatch: false, queryType: "random", username: user.username
              }
            });
          }
          catch (error) {
          }
        }
        else {
          showSpinner(false);
          alert("No Batch available! Please come back later!");
        }
      })
      .catch((error) => {
        showSpinner(false);
        alert("This batch is no longer available. Please, refresh the page and try again.");
      });
  }, [fullLabelData, labelData, props.history, user.username]);

  // start quiz by index `i` in `quizzes` array
  const startQuiz = useCallback(async (i: number) => {
    showSpinner(true);
    const quiz = quizzes.data[i];
    const quiz_questions = await getFetch(`/training/quiz/${quiz.id}/question?localtime=${getDateTime()}`, {});
    props.history.push({
      pathname: "/training/quiz",
      state: {
        quiz: quiz,
        quiz_questions: quiz_questions,
        labelData: labelData,
        fullLabelData: fullLabelData,
        mode: "quiz"
      }
    });
    showSpinner(false);
  }, [fullLabelData, labelData, props.history, quizzes.data]);

  const startLevel1Exam = useCallback(async () => {
    showSpinner(true);
    const exam = {
      data: {
        name: "Level 1 Exam",
        tolerance: 15,
        passing_mark: 75
      }
    };
    const exam_questions = await getFetch(`/training/exam?localtime=${getDateTime()}`, {});
    props.history.push({
      pathname: "/training/level-1-exam",
      state: {
        quiz: exam,
        quiz_questions: exam_questions,
        labelData: labelData,
        fullLabelData: fullLabelData,
        mode: "level1exam"
      }
    });
    showSpinner(false);
  }, [fullLabelData, labelData, props.history]);

  const getKitronCheck = useCallback((queryType: String) => {
    showSpinner(true);

    let GATEWAY_URL = "";
    let checkBatch = false;

    switch (queryType) {
      case "consensus":
      case "checking":
        GATEWAY_URL = `/get-batch-for-kitrons?queryType=${queryType}`;
        checkBatch = true;
        break;

      case "random":
        if (!nBatchData.loaded || nBatchData.error || (nBatchData.data.length === 0)) {
          showSpinner(false);
          return;
        }
        startAvailableBatch(nBatchData.data[0]);
        return;

      case "segmentation":
        GATEWAY_URL = `/get-segmentation-batch?queryType=${queryType}`;
        break;

      case "segmentation_checking":
        GATEWAY_URL = `/get-segmentation-checking-batch?queryType=${queryType}`;
        break;

      case "training":
        showSpinner(false);
        props.history.push("/training");
        return;

      case "quiz":
        showSpinner(false);
        startQuiz(0);
        return;

      case "level1exam":
        showSpinner(false);
        startLevel1Exam();
        return;
    }

    getFetch(GATEWAY_URL, {})
      .then((data) => {
        if (Object.keys(data).length) {
          try {
            let pathname = "";
            switch (queryType) {
              case "segmentation":
                pathname = "/segmentation";
                break;
              case "segmentation_checking":
                pathname = "/segmentation_checking";
                break;
              default:
                pathname = "/labelling";
                break;
            }

            props.history.push({
              pathname: pathname,
              state: {
                batchData: data,
                labelData: labelData,
                fullLabelData: fullLabelData,
                checkBatch: checkBatch,
                queryType: queryType,
                username: user.username
              }
            });

            showSpinner(false);
          }
          catch (error) {
          }
        }
        else {
        //TODO: Handle this
          showSpinner(false);
          alert("This batch is not available, please come back later!");
        }
      })
      .catch((error) => {
        showSpinner(false);
        alert(error);
      });
  }, [labelData, fullLabelData, nBatchData.data, user, nBatchData.error, nBatchData.loaded, props.history, startAvailableBatch, startLevel1Exam, startQuiz]);

  if (!user.username)
    return <div>Loading.. please wait!</div>;

  const checkOutFeedback = () => {
    props.history.push("/feedback");
  };

  if (user.labellingInfo.level === 0) {
    user.labellingInfo.has_quizzes = quizzes.loaded && quizzes.data.length > 0;
    user.labellingInfo.can_take_exam = quizzes.loaded && quizzes.data.length === 0;
  }

  let right_panel_contents: React.ReactElement<any, any>;
  if (user.currentRole === "Labelling" && user.labellingInfo.level === 0)
    right_panel_contents = <AvailableQuizzesBox quizzes={quizzes.loaded ? quizzes.data : []} startQuiz={startQuiz} />;
  else if (user.currentRole === "Labelling" && user.labellingInfo.level >= 1)
    right_panel_contents = <AvailableBatchesBox batchData={nBatchData} openBatches={0} totalBatches={0} startBatchFunction={startAvailableBatch}/>;


  const lcc = <LiveCountComponent key="lcc" role={user.currentRole.toLowerCase()} loadCallback={(maxed) => setMaxedOut(maxed)} />;
  const maxedOutMsg = "You reached your maximum allowed number of tasks today.";

  return (
    <div className="animated fadeIn batchOverviewMaster">
      <div className="batchOverview">
        <div className="batchOverviewPanel">
          {(maxedOut !== false) && <p>{(maxedOut === null) ? "Loading..." : maxedOutMsg}</p>}
          <StartBatchPanel pageTitle={"IMAGE " + user.currentRole.toUpperCase()} userData={user} getKitronCheckFunction={getKitronCheck} lcc={lcc} show={maxedOut === false}/>
        </div>
        <div className="batchOverviewPanel">
          <UserOverviewBox />
          {
            (maxedOut === false)
              ? right_panel_contents
              : <p>{(maxedOut === null) ? "Loading..." : maxedOutMsg}</p>
          }
        </div>
      </div>
      <FeedbackBox userData={user} buttonFunction={checkOutFeedback}/>
    </div>
  );
};

const getUnfinishedQuizzes = (data: Array<Quizzes>) => data.filter((q) => (q.passing_mark === null || q.u_score < q.passing_mark));

export default BatchesOverview;
